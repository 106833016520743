import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Erro401 from '../components/errors/401';
import jwt_decode from 'jwt-decode';
import AppContext from '../context/Context';

const PrivateRoute = (props) => {

    const [auth, setAuth] = useState(false);
    const location = useLocation();
    const params = useParams();

    const token = localStorage.getItem(process.env.REACT_APP_NAMETOKEN);
    const payload = token ? jwt_decode(token) : {};
    const { contextValue, setContextValue } = useContext(AppContext);


    // Sem acesso ou o token está expirado 
    const agora = new Date();
    const timestampUnix = Math.round(agora.getTime() / 1000);
    if (!payload?.exp || payload?.exp < timestampUnix) {
        localStorage.clear();
        setContextValue({ isAuthenticated: false });
    }

    useEffect(() => {

        if (!contextValue?.allowed) {

            setAuth(false);

        } else {

            const menus_permitidos = contextValue?.allowed || [];
            if (!menus_permitidos.includes(location.pathname)) {

                if (params) {
                    var params_keys = Object.keys(params);

                    var menus = [];
                    params_keys.map(p => {
                        menus_permitidos.map(m => {
                            if (m.indexOf(`/:${p}`) > -1) {
                                menus.push(m.replace(`:${p}`, params[p]));
                            }
                            return false;
                        });
                        return true;
                    });

                    if (menus.includes(location.pathname)) {
                        setAuth(true);
                    } else {
                        setAuth(false);
                    }
                } else {
                    setAuth(false);
                }

            } else {
                setAuth(true);
            }
        }

    }, [params, location.pathname, contextValue?.allowed]);

    // SE É ADMIN SEGUE A FESTA
    if (contextValue?.isAdmin) return <Outlet />;


    // AQUI VERIFICA SE A ROTA ESTÁ PERMISSIONADA
    return auth ? <Outlet /> : <Erro401 />;
}

export default PrivateRoute;