import React, { useEffect, useState } from "react";
import FormPadrao from "../../utils/form";
// import { mutate } from 'swr';
import { Button, Modal } from "react-bootstrap";
import useLoadData from "../../../data/swr/useLoadData";


const ModalClientModules = props => {

    const [modalShow, setModalShow] = useState(false);
    const [modules, setModules] = useState([]);

    const { data, isLoading } = useLoadData(`/adm/modules`);


    function onSubmitSuccess() {
        setModalShow(false);
        if (typeof (props.onSubmitSuccess) == "function") props.onSubmitSuccess();
    }

    useEffect(() => {

        const modulos = [{ "label": "Selecione...", "value": "", "disabled": true }];
        data?.modules.map(m => {
            modulos.push({
                "label": `${m.id} - ${m.name}`,
                "value": m.id
            });
            return true;
        });

        setModules(modulos);

    }, [data?.modules]);

    const formEdit = {
        // formType: 'prepend',
        action: '/adm/modules/client',
        method: 'POST',
        data: props?.module,
        onSubmitSuccess,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: '9', required: 'Nome não informado!' },
            { name: 'active', type: 'select', label: 'Situação', col: '3', options: [{ label: 'Ativo', value: true }, { label: 'Inativo', value: false }] },
            { name: 'icon', type: 'text', label: 'Icone', col: '4', required: 'Icone não informado!' },
            { name: 'module_id', type: 'select', label: 'Tipo', col: '6', required: "Selecione o tipo do módulo!", options: modules, checked: "", disabled: !!props?.module },
            { name: 'or', type: 'number', label: 'Ordem Exibição', col: '2', required: 'Ordem de exibição não informada!' },
            { name: 'description', type: 'textarea', label: 'Descrição', col: '12' },
            { name: 'adm_client_id', type: 'hidden', label: 'Cliente', value: props?.client || false },
        ],
        submits: [
            { label: 'Salvar', class: "btn btn-primary" }
        ],
    };

    if (props?.module) formEdit.method = 'PUT';
    if (props?.module) formEdit.action = formEdit.action + '/' + props?.module?.id;

    return (
        <>
            {
                props?.module ? (
                    <Button className="btn btn-falcon-link btn-sm p-0 border-0 shadow-none" variant="" onClick={() => setModalShow(true)}>
                        <span className="fas fa-edit"></span>
                        <span className="d-none d-sm-inline-block ms-1">Editar</span>
                    </Button>
                ) : (
                    <Button className="btn btn-falcon-primary btn-sm" variant="" onClick={() => setModalShow(true)}>
                        <span className="fas fa-plus"></span>
                        <span className="d-none d-sm-inline-block ms-1">Novo</span>
                    </Button>
                )
            }
            <Modal
                show={modalShow && !isLoading}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <div className="">
                        <h4 className="mb-1" id="staticBackdropLabel">Criar/Editar Módulo</h4>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalClientModules;