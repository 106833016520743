import React, { useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useLoadData from "../../../data/swr/useLoadData";
import RequestError from "../../utils/request/RequestError";
import RequestLoading from "../../utils/request/RequestLoading";
import FormPadrao from "../../utils/form";
import { mutate } from "swr";
import bg_holder from '../../utils/icon/corner-4.png'
import useScrollSpy from 'react-use-scrollspy';
import ModalNavAdd from "./nav/ModalNavAdd";
import ModalNavEdit from "./nav/ModalNavEdit";
import ModalNavDelete from "./nav/ModalNavDelete";



const ModuleById = props => {

    const { cid } = useParams();
    const { data, error, isLoading } = useLoadData(`/adm/modules/${cid}`);

    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -150
    });

    function onSubmitSuccess() {
        mutate(`/adm/modules/${cid}`);
    }


    const formEdit = {
        // formType: 'prepend',
        action: `/adm/modules/${cid}`,
        method: 'PUT',
        data: data?.module,
        onSubmitSuccess,
        fields: [
            { name: 'id', type: 'number', label: 'ID', col: '2', required: 'ID não informado!', disabled: true },
            { name: 'name', type: 'text', label: 'Nome', col: '7', required: 'Nome não informado!', autoFocus: true },
            { name: 'active', type: 'select', label: 'Situação', col: '3', options: [{ label: 'Ativo', value: true }, { label: 'Inativo', value: false }] },
            { name: 'path', type: 'text', label: 'Link', col: '8', required: 'Link não informado!', disabled: true },
            { name: 'icon', type: 'text', label: 'Icone', col: '4', required: 'Icone não informado!' },
            { name: 'description', type: 'textarea', label: 'Descrição', col: '12' },
        ],
        submits: [
            { label: 'Atualizar', class: "btn btn-primary" }
        ],
    };




    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    return (
        <>
            <Card className="mb-3">
                <div className="bg-holder d-none d-lg-block bg-card" style={{ "backgroundImage": `url(${bg_holder})` }}></div>
                <Card.Body className="card-body position-relative">
                    <Row className="justify-content-between align-items-center">
                        <Col lg="8">
                            <h3>{data?.module?.icon ? <span className="me-2"><span className={data?.module?.icon}></span></span> : ''}{data?.module?.name}</h3>
                            <p className="mb-0">
                                <small>Cadastro atualizado em {data?.module?.updated_at}.</small>
                            </p>
                        </Col>
                        <Col lg="auto">
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row>
                <Col lg="3">
                    <Card className="sticky-top py-4" style={{ "top": "var(--falcon-top-nav-height)" }}>
                        <ul className="scrollspy" id="account" role="tablist">
                            <li className={`${activeSection === 0 ? 'active' : ''}`}>
                                <a href="#cadastro" className="d-flex align-items-center py-3">
                                    <span className="far fa-edit"></span>&nbsp;Cadastro
                                </a>
                            </li>
                            <li className={`${activeSection === 1 ? 'active' : ''}`}>
                                <a href="#menus" className="d-flex align-items-center py-3">
                                    <span className="fas fa-code-branch"></span>&nbsp;Menus
                                </a>
                            </li>
                        </ul>
                    </Card>
                </Col>
                <Col lg="9">
                    <div data-spy="scroll" data-bs-target="#account" data-bs-smooth-scroll="true" data-bs-root-margin="0px 0px -40%" className="--bg-body-tertiary" tabIndex="0">
                        {/* <InputLabelPrepend checked={data?.client?.active} type="checkbox" label="Situação" value="1" register={{ ...register('active') }} disabled={disabled} /> */}
                        <div className="" id="cadastro" ref={sectionRefs[0]}>
                            <Card className="mb-3">
                                <Card.Header>
                                    Cadastro
                                </Card.Header>
                                <Card.Body>
                                    <FormPadrao {...formEdit} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="" id="menus" ref={sectionRefs[1]}>
                            <Card className="mb-3">
                                <Card.Header>
                                    <Row className="justify-content-between">
                                        <Col lg="auto">
                                            Menus
                                        </Col>
                                        <Col lg="auto">
                                            {data?.navs?.length ? <ModalNavAdd md={cid} /> : ''}
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    {data?.navs?.length ? menu_tree(data?.navs, cid) : <>
                                        <Row className="justify-content-center">
                                            <Col lg="8">
                                                <div className="text-center p-4 p-sm-5">
                                                    <span className="fw-black lh-1 text-300 fs-error">
                                                        <span className="fas fa-code-branch"></span>
                                                    </span>
                                                    <p className="mt-3">Não há menus cadastrados no momento. <br /> Por favor, cadastre um novo menus para continuar.</p>
                                                    <ModalNavAdd md={cid} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>}
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </Col>
            </Row >
        </>
    );
}


export default ModuleById;


function menu_tree(m, cid) {
    const mp = m?.map((menu) => {
        const ul = (
            <ul className="m-0" key={menu.id}>
                <li className={
                    menu?.menus?.length
                        ? "menu"
                        : "sub-menu"
                }>
                    <div className="title p-1">
                        <ModalNavEdit md={cid} nav={menu.id} name={`#${menu.id} - ${menu.name}`} />
                        <ModalNavAdd md={cid} nav={menu.id} />
                        <ModalNavDelete md={cid} nav={menu.id} />
                    </div>
                    {menu_tree(menu.menus, cid)}
                </li>
            </ul>
        );

        return ul;
    });
    return mp;
}