import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import Inputmask from 'inputmask';
import { v4 as hash } from 'uuid';
import api from '../../../data/axios/api';
import { useForm } from 'react-hook-form';
import ShowError from '../request/ShowError';
import InputDrive from './InputDrive';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
// import InputSelect from "./InputSelect";
// import InputDate from "./InputDate";
const theme = localStorage.getItem('data-bs-theme') || 'light';

export const FormPadrao = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [driveFolder, setDriveFolder] = useState(null);
  const [driveFiles, setDriveFiles] = useState([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setError,
    watch,
    clearErrors,
  } = useForm();

  /*
    const formEdit = {
        formType: 'prepend',
        action: '/adm/users/' + uid,
        data: data?.user,
        onSubmitSuccess,
        groups: [
            {
                header: 'Top',
                fields: [
                    { name: 'fantasy', type: 'text', label: 'Nome', col: '12', required: 'Nome não informado!', autoFocus: true },
                    { name: 'document_number', type: 'text', label: 'Documento', col: '12', mask: '99.999.999/9999-99', required: 'Documento não informado!' },
                ],
                footer: 'Bottom',
            }
        ],
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: 8, required: 'Nome não informado!', autoFocus: true },
            { name: 'surname', type: 'text', label: 'Apelido', col: 4 },
            { name: 'phone', type: 'tel', label: 'Telefone', col: 4, mask: "(99) 99999-9999", required: "Telefone não informado!" },
            { name: 'document_number', type: 'text', label: 'Documento', col: 4, mask: "999.999.999-99" },
            { name: 'birth', type: 'date', label: 'Nascimento', col: 4 },
            { name: 'email', type: 'email', label: 'E-mail', col: 12, required: 'E-mail não informado!' },
        ],
        submits: [
            { label: 'Atualizar', class: "btn btn-primary" }
        ],
    };
    <FormPadrao {...formEdit} />
    */

  const action = props?.action || '/';
  const method = props?.method || 'POST';
  const fields = props?.fields;
  const groups = props?.groups;
  var submits = props?.submits;
  const formularioRef = useRef(null);

  useEffect(() => {
    if (formularioRef.current) {
      formularioRef.current.querySelectorAll('[data-inputmask]').forEach((element, index, array) => {
        Inputmask().mask(element);
      });
    }
  }, [formularioRef]);

  useEffect(() => {
    var dados = props?.data || {};

    const keys = Object.keys(dados);
    if (!keys.length) {
      clearErrors();
      reset();
    } else {
      reset({ ...dados });
    }

    // MARCANDO AS OPÇÕES DE CHECKBOX
    setTimeout(() => {
      keys.map((e) => {
        if (e === 'drive_folder') setDriveFolder(dados[e]);

        if (typeof dados[e] === 'object') {
          var obj = dados[e] || [];
          obj?.map((d) => {
            const input = document.querySelector(`[name="${e}"][value="${d}"]`);
            if (input && input.type === 'checkbox') {
              input.checked = true;
            }
            return true;
          });
        }
        return true;
      });
    }, 1);

    // console.log('FormPadrao', props?.data);
    // eslint-disable-next-line
  }, [props?.data]);

  useEffect(() => {
    if (props?.drive) setDriveFiles(props.drive);
  }, [props?.drive]);

  async function onSubmit(formData) {
    try {
      setDisabled(true);

      switch (method) {
        case 'POST':
          await api.post(action, formData);
          break;
        case 'PUT':
          await api.put(action, formData);
          break;
        case 'DELETE':
          await api.delete(action);
          break;
        default:
          break;
      }

      if (typeof props.onSubmitSuccess === 'function') props.onSubmitSuccess();
      toast.success(<span style={{ fontSize: '15px' }}>Operação realizada com sucesso!</span>, {
        position: 'bottom-left',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: theme,
        toastId: 'toast-ok',
        // icon: ({ theme, type }) => <img />
      });

      reset();
      clearErrors();
      setDisabled(false);
    } catch (error) {
      setDisabled(false);

      if (typeof props.onSubmitError === 'function') props.onSubmitError();

      const errorResponse = error?.response?.data || {};
      const errorName = errorResponse.error?.name;
      const errorMessageName = errorResponse.error?.message?.name;

      if (errorName && errorName.indexOf(',') === -1 && watch(errorName) !== undefined)
        return setError(errorName, { type: 'requestError', message: errorResponse.error?.error });

      if (errorMessageName && errorMessageName.indexOf(',') === -1 && watch(errorMessageName) !== undefined)
        return setError(errorMessageName, { type: 'requestError', message: errorResponse.error?.message?.error });

      if (errorMessageName && errorMessageName.indexOf(',') > -1) {
        errorMessageName.split(',').map((name) => {
          name = name.trim();
          if (watch(name) !== undefined) return setError(name, { type: 'requestError', message: errorResponse.error?.message?.error });
          return true;
        });
      }

      if (errorResponse.error?.message) return ShowError(errorResponse.error?.message);

      if (error?.code === 'ERR_NETWORK') return ShowError('Você está off-line!');

      console.log(error);
      return ShowError(error);
    }
  }

  // BUSCANDO OS DADOS DO CEP
  register('postal_code', {
    onChange: async (e) => {
      try {
        var cep = e.target.value.replace(/[^0-9]/g, '');
        if (cep?.length !== 8) return false;
        setDisabled(true);
        var cep_name = [];
        const cep_info = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
        if (cep_info?.data) cep_name = Object.keys(cep_info.data);

        const data_cep = {};
        cep_name.map((name) => {
          switch (name) {
            case 'bairro':
              data_cep.district = cep_info.data[name];
              break;
            case 'cep':
              data_cep.postal_code = cep_info.data[name];
              break;
            case 'logradouro':
              data_cep.address = cep_info.data[name];
              break;
            case 'uf':
              data_cep.uf = cep_info.data[name];
              break;
            case 'localidade':
              data_cep.city = cep_info.data[name];
              break;
            case 'ibge':
              data_cep.ibge = cep_info.data[name];
              break;
            default:
              break;
          }
          return true;
        });

        reset({ ...data_cep });
        setDisabled(false);
        return true;
      } catch (error) {
        setDisabled(false);
        console.log(error);
        return false;
      }
    },
  });

  // BUSCANOD OS DADOS DA EMPRESA
  register('document_number', {
    onChange: async (e) => {
      try {
        var cnpj = e.target.value.replace(/[^0-9]/g, '');
        if (cnpj?.length !== 14) return false;
        setDisabled(true);
        var cnpj_name = [];
        var cnpj_info = await axios.get(`https://publica.cnpj.ws/cnpj/${cnpj}`);

        if (cnpj_info?.data) {
          cnpj_info = { ...cnpj_info?.data, ...cnpj_info?.data?.estabelecimento };
          cnpj_name = Object.keys(cnpj_info);
        } else {
          return false;
        }

        const data_cnpj = {};
        cnpj_name.map((name) => {
          switch (name) {
            case 'razao_social':
              data_cnpj.name = cnpj_info[name];
              break;
            case 'email':
              data_cnpj.email = cnpj_info[name];
              break;
            case 'telefone1':
              data_cnpj.phone = cnpj_info[name];
              break;
            case 'porte':
              data_cnpj.company_size = cnpj_info[name]['descricao'];
              break;
            case 'atividade_principal':
              data_cnpj.activity_primary = cnpj_info[name]['classe'] + ' - ' + cnpj_info[name]['descricao'];
              break;
            case 'cep':
              data_cnpj.postal_code = cnpj_info[name];
              break;
            case 'socios':
              if (!cnpj_info[name].length) break;
              var socios = cnpj_info[name][0];
              data_cnpj.responsible_name = socios?.nome;
              data_cnpj.responsible_document_number = socios?.cpf_cnpj_socio;
              data_cnpj.responsible_position = socios?.qualificacao_socio?.descricao;
              break;
            default:
              break;
          }
          return true;
        });

        reset({ ...data_cnpj });
        setDisabled(false);

        return true;
      } catch (error) {
        setDisabled(false);
        console.log(error);
        return false;
      }
    },
  });

  return (
    <>
      <form ref={formularioRef} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
          {groups?.length
            ? groups?.map((g, index) => {
                var group_hash = hash();
                return (
                  <div className="col-12" key={index}>
                    <div className="" id={g?.id || 'group-' + group_hash} ref={g?.ref || null}>
                      <Card className="mb-3">
                        {g?.header && <Card.Header>{g?.header}</Card.Header>}
                        <Card.Body>
                          {g?.fields?.map((f, index) => (
                            <React.Fragment key={index}>
                              {generation_input({ f, register, errors, props, disabled: f?.disabled || disabled, driveFolder, driveFiles })}
                            </React.Fragment>
                          ))}
                          {g?.submit && (
                            <>
                              <div className="d-flex justify-content-end align-items-center mt-3">
                                <button type="submit" className={`${''} ${g?.submit?.class || ''}`}>
                                  {disabled ? (
                                    <>
                                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </>
                                  ) : (
                                    g?.submit?.label || g?.submit?.title
                                  )}
                                </button>
                              </div>
                            </>
                          )}
                        </Card.Body>
                        {g?.footer && <Card.Footer>{g?.footer}</Card.Footer>}
                      </Card>
                    </div>
                  </div>
                );
              })
            : fields?.map((f, index) => (
                <React.Fragment key={index}>
                  {generation_input({ f, register, errors, props, disabled: f?.disabled || disabled, driveFolder, driveFiles })}
                </React.Fragment>
              ))}
          {props?.dataModules?.length ? (
            <div className="col-12" key={hash()}>
              <div className="border p-3">
                <h5>Configurando Permissões</h5>
                <hr />
                {props?.dataModules?.map((md) => {
                  return (
                    <ul className="treeview" key={md?.adm_client_module_id}>
                      <li className="treeview-list-item">
                        <p className="treeview-text">{md?.name}</p>
                        {menu_tree(md?.menus, { register })}
                      </li>
                    </ul>
                  );
                })}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="d-flex justify-content-end-- justify-content-between align-items-center mt-3">
          <div className="me-3">
            {props?.data?.updated_at && submits?.length && (
              <small>
                <div>Last Update</div>
                <div>
                  <Moment format="DD/MM/YYYY [às] HH:MM:SS">{props?.data?.updated_at}</Moment>
                </div>
              </small>
            )}
          </div>
          <div className="btn-group-" role="group">
            {submits?.map((s, index) => {
              return (
                <button
                  key={index}
                  type={s?.type || 'submit'}
                  onClick={typeof s?.onClick == 'function' ? s?.onClick : () => false}
                  className={`${index >= 1 ? 'ms-1' : ''} ${s?.class || ''}`}
                >
                  {disabled ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </>
                  ) : (
                    s?.label || s?.title
                  )}
                </button>
              );
            })}
          </div>
        </div>
      </form>
    </>
  );
};

export const InputLabelPrepend = (props) => {};

function generation_input(dados = {}) {
  const { f, register, errors, props, disabled, driveFolder, driveFiles } = dados;

  const name = f?.name || '';
  var label = f?.label || '';
  const col = f?.col || 12;
  const type = f?.type || 'text';
  const input_id = hash();
  const mask = f?.mask ? "'mask':'" + f?.mask + "'" : '';
  const html = f?.html || '';
  const focus = f?.autoFocus ? true : false;
  const checked = f?.checked ? true : false;
  const value = f?.value || '';

  var formType = props?.formType;

  var registerAux = register(name);
  if (f?.required) {
    registerAux = register(name, { required: true });
  }

  var input = '<!--Input Default-->';
  switch (type) {
    case 'string':
      input = <div>{html}</div>;
      break;
    case 'drive':
      input = <InputDrive title={label} folder={driveFolder} fileList={driveFiles} />;
      label = false;
      formType = false;
      break;
    case 'image':
      break;
    case 'time':
      break;
    case 'select':
      input = [];
      f?.options?.map((op, i) => {
        input.push(
          <option key={i} value={op?.value} disabled={op?.disabled ? true : false}>
            {op?.label}
          </option>
        );
        return true;
      });
      input = (
        <select className="form-control" id={input_id} autoFocus={focus} disabled={disabled} defaultValue={checked} {...registerAux}>
          {input}
        </select>
      );

      break;
    case 'checkbox':
      input = [];
      f?.options?.map((op, i) => {
        const check_id = hash();
        input.push(
          <div key={i} className="form-check">
            <input
              className="form-check-input"
              id={check_id}
              disabled={disabled}
              type="checkbox"
              value={op?.value}
              checked={op?.checked ? true : false}
              placeholder={op?.label}
              {...registerAux}
            />
            <label className="form-check-label" htmlFor={check_id}>
              {op?.label}
            </label>
          </div>
        );
        return true;
      });
      input = <>{input}</>;
      break;
    case 'date':
      // input = (<InputDate />);
      input = (
        <input
          className="form-control"
          data-inputmask={mask}
          type={type}
          id={input_id}
          autoFocus={focus}
          disabled={disabled}
          placeholder={label}
          {...registerAux}
        />
      );
      break;
    case 'textarea':
      input = (
        <textarea
          className="form-control"
          id={input_id}
          disabled={disabled}
          rows="3"
          autoFocus={focus}
          placeholder={label}
          {...registerAux}
        ></textarea>
      );
      break;
    default:
      input = (
        <input
          className="form-control"
          data-inputmask={mask}
          type={type}
          id={input_id}
          autoFocus={focus}
          disabled={disabled}
          placeholder={label}
          defaultValue={value}
          {...registerAux}
        />
      );
      break;
  }

  // TIPO DE ESTRUTURA A DEFINIR
  switch (formType) {
    case 'prepend':
      input = (
        <div className={`col-12`}>
          <div className="mb-2">
            <Row className="align-items-center">
              <Col lg="3">
                <label className="fs-0 col-form-label" htmlFor={input_id}>
                  {label}
                  <small className="ms-1 text-muted">{f?.required ? '(Obrigatório)' : '(Opcional)'}</small>
                </label>
              </Col>
              <Col>
                <div className="form-floating--">
                  {input}
                  {/* {label && <label className="" htmlFor={input_id}>{label}</label>} */}
                  {errors[name]?.type === 'required' && <small className="text-danger">{f?.required}</small>}
                  {errors[name]?.type === 'requestError' && <small className="text-danger">{errors[name]?.message}</small>}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
      break;
    default:
      input = (
        <div className={`col-lg-${col}`}>
          <div className={type !== 'hidden' ? 'mb-2 form-floating' : 'input-hidden d-none'}>
            {input}
            {label && (
              <label className="" htmlFor={input_id}>
                {label}
                <small className="ms-1 text-muted">{f?.required ? '(Obrigatório)' : '(Opcional)'}</small>
              </label>
            )}
            {errors[name]?.type === 'required' && <small className="text-danger">{f?.required}</small>}
            {errors[name]?.type === 'requestError' && <small className="text-danger">{errors[name]?.message}</small>}
          </div>
        </div>
      );
      break;
  }

  return input;
}

// USADO PARA TRATAR A ESTRUTURA DOS MENUS
function menu_tree(m, dados = {}) {
  const { register } = dados;
  const mp = m?.map((menu) => {
    const input_hash = hash();
    const ul = (
      <ul className="treeview-list" key={menu.adm_client_module_id ? menu.adm_client_module_id + '.' + menu.id : menu.id}>
        <li className="treeview-list-item">
          <div className="form-check">
            <label htmlFor={input_hash}>{menu.name}</label>
            <input
              className="form-check-input"
              id={input_hash}
              {...register('navigation')}
              value={menu.adm_client_module_id ? menu.adm_client_module_id + '.' + menu.id : menu.id}
              type="checkbox"
            />
          </div>
          {menu_tree(menu.menus, dados)}
        </li>
      </ul>
    );

    return ul;
  });
  return mp;
}

export default FormPadrao;
