import React, { useState } from 'react';
import AppContext from './context/Context';
import jwt_decode from 'jwt-decode';

const Main = (props) => {
    const token = localStorage.getItem(process.env.REACT_APP_NAMETOKEN);
    var schema = localStorage.getItem(process.env.REACT_APP_NAMESCHEMA);
    const isAuthenticated = !!token;
    const payload = isAuthenticated ? jwt_decode(token) : {};
    switch (true) {
        case !schema && payload?.schema:
            schema = payload?.schema;
            localStorage.setItem(process.env.REACT_APP_NAMESCHEMA, schema);
            break;
        case schema && !payload?.schema:
            payload.schema = schema;
            localStorage.setItem(process.env.REACT_APP_NAMESCHEMA, schema);
            break;
        default:
            break;
    }
    const [contextValue, setContextValue] = useState({ schema, ...payload, token, isAuthenticated });

    return <AppContext.Provider value={{ contextValue, setContextValue }}>{props.children}</AppContext.Provider>;
};

export default Main;
