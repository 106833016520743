import React, { useState } from "react";
import { Button, Modal, Row, Col, Tabs, Tab } from "react-bootstrap";
import FormPadrao from "../../../../utils/form";
import { v4 as hash } from 'uuid';
import { mutate } from 'swr';

const ModalFornecedor = props => {


    const [modalShow, setModalShow] = useState(false);
    const [type, setType] = useState('PF');

    const hashPF = hash();
    const hashPJ = hash();

    function onSubmitSuccess() {
        setModalShow(false);
        if (typeof (props.onSubmitSuccess) == "function") props.onSubmitSuccess();
        mutate(`/${props.mod}/${props.cmodule}/fornecedores`);
    }


    const fields_pf = [
        { name: 'name', type: 'text', label: 'Nome', col: '6', required: 'Nome não informado!' },
        { name: 'surname', type: 'text', label: 'Apelido', col: '4' },
        { name: 'birth', type: 'date', label: 'Data Nascimento', col: '2' },

        { name: 'document_number', type: 'text', label: 'CPF', col: '4', mask: '999.999.999-99' },
        { name: 'register_number', type: 'text', label: 'RG', col: '3' },
        { name: 'register_issuer', type: 'text', label: 'RG - Orgão Emissor', col: '2' },
        { name: 'register_date', type: 'date', label: 'RG - Data Emissão', col: '3' },

        { name: 'email', type: 'email', label: 'E-mail', col: '6' },
        { name: 'phone', type: 'text', label: 'Telefone', col: '3', mask: '(99) 99999-9999' },
        { name: 'whatsapp', type: 'text', label: 'Whatsapp', col: '3', mask: '(99) 99999-9999' },

        { name: 'note_name', type: 'text', label: 'Nome - Recado', col: '6' },
        { name: 'note_email', type: 'email', label: 'E-mail - Recado', col: '3', mask: '(99) 99999-9999' },
        { name: 'note_phone', type: 'text', label: 'Telefone - Recado', col: '3', mask: '(99) 99999-9999' },

        { name: 'postal_code', type: 'text', label: 'CEP', col: '2', mask: '99999-999' },
        { name: 'city', type: 'text', label: 'Cidade', col: '4' },
        { name: 'uf', type: 'text', label: 'Estado', col: '3' },
        { name: 'country', type: 'text', label: 'País', col: '3' },

        { name: 'district', type: 'text', label: 'Bairro', col: '4' },
        { name: 'number', type: 'text', label: 'Número', col: '2' },
        { name: 'ibge', type: 'number', label: 'IBGE', col: '2' },
        { name: 'address', type: 'text', label: 'Endereço', col: '4' },
        { name: 'complement', type: 'textarea', label: 'Complemento', col: '12' },

        { name: 'type', type: 'hidden', label: 'Tipo', value: type },
    ];

    const optionsPorte = [{ label: 'Selecione...', value: '', seleted: true, disabled: true }, { label: 'Micro', value: 'Micro' }, { label: 'Pequena', value: 'Pequena' }, { label: 'Média', value: 'Média' }, { label: 'Grande', value: 'Grande' }];
    const fields_pj = [
        { name: 'fantasy', type: 'text', label: 'Nome Fantasia', col: '6', required: 'Nome não informado!' },
        { name: 'name', type: 'text', label: 'Razão social', col: '6', required: 'Razão social não informado!' },

        { name: 'document_number', type: 'text', label: 'CNPJ', col: '4', mask: '99.999.999/9999-99' },
        { name: 'registration_number', type: 'text', label: 'Inscrição Estadual', col: '3' },
        { name: 'activity_primary', type: 'text', label: 'Atividade Primária', col: '3' },
        { name: 'company_size', type: 'select', label: 'Porte da Empresa', col: '2', options: optionsPorte },

        { name: 'email', type: 'email', label: 'E-mail', col: '6' },
        { name: 'phone', type: 'text', label: 'Telefone', col: '3', mask: '(99) 99999-9999' },
        { name: 'whatsapp', type: 'text', label: 'Whatsapp', col: '3', mask: '(99) 99999-9999' },

        { name: 'postal_code', type: 'text', label: 'CEP', col: '2', mask: '99999-999' },
        { name: 'city', type: 'text', label: 'Cidade', col: '4' },
        { name: 'uf', type: 'text', label: 'Estado', col: '3' },
        { name: 'country', type: 'text', label: 'País', col: '3' },

        { name: 'district', type: 'text', label: 'Bairro', col: '4' },
        { name: 'number', type: 'text', label: 'Número', col: '2' },
        { name: 'ibge', type: 'number', label: 'IBGE', col: '2' },
        { name: 'address', type: 'text', label: 'Endereço', col: '4' },
        { name: 'complement', type: 'textarea', label: 'Complemento', col: '12' },

        { name: 'type', type: 'hidden', label: 'Tipo', value: type },
    ];


    const formEdit = {
        action: `/${props.mod}/${props.cmodule}/fornecedores`,
        method: 'POST',
        onSubmitSuccess,
        fields: [],
        submits: [
            { label: 'Salvar', class: "btn btn-primary" }
        ],
    };


    return (
        <>
            <Button className="btn-falcon-default btn-sm mx-2" onClick={() => setModalShow(true)}>
                <i className="fas fa-user-plus fs--2"></i>
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Row className="align-items-center">
                        <Col lg={'auto'}>
                            <h4 className="mb-1">Novo Fornecedor</h4>
                        </Col>
                        <Col lg={'auto'}>
                            <div className="form-check form-check-inline mb-0 ms-3">
                                <input className="form-check-input" id={hashPJ} type="radio" value="PJ" name="type" onClick={() => setType('PJ')} defaultChecked={type === "PJ" ? true : false} />
                                <label className="form-check-label mb-0" htmlFor={hashPJ}>Pessoa Jurídica</label>
                            </div>
                            <div className="form-check form-check-inline mb-0">
                                <input className="form-check-input" id={hashPF} type="radio" value="PF" name="type" onClick={() => setType('PF')} defaultChecked={type === "PF" ? true : false} />
                                <label className="form-check-label mb-0" htmlFor={hashPF}>Pessoa Física</label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={type}
                        className="mb-3"
                    >
                        <Tab eventKey="PF" title="">
                            <FormPadrao {...formEdit} fields={fields_pf} />
                        </Tab>
                        <Tab eventKey="PJ" title="">
                            <FormPadrao {...formEdit} fields={fields_pj} />
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalFornecedor;