import React from "react";
import useLoadData from "../../../../data/swr/useLoadData";
import RequestError from "../../../utils/request/RequestError";
import RequestLoading from "../../../utils/request/RequestLoading";
import { Card, Col, Row } from "react-bootstrap";
import ModalNewColab from "./ModalNewColab";
import { Link } from "react-router-dom";
import AvatarName from "../../../adm/utils/AvatarName";

const Colaboradores = props => {
    const { data, error, isLoading } = useLoadData('/empresa/colaboradores');
    const workers = data?.workers;

    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    if (!workers?.length) return (
        <Row className="justify-content-center">
            <Col lg="6">
                <Card>
                    <Card.Body className=" p-4 p-sm-5">
                        <div className="text-center">
                            <span className="fw-black lh-1 text-300 fs-error">
                                <span className="fas fa-users"></span>
                            </span>
                            <p className="mt-3">Não há colaboradores cadastrados no momento. <br /> Por favor, cadastre um novo colaborador para continuar.</p>
                            <ModalNewColab />
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );

    return (
        <>
            <Row className="mb-3 justify-content-between">
                <Col md="auto">
                    <h4 className="m-0 d-none">Colaboradores<span className="fw-normal ms-1">({workers?.length})</span></h4>
                </Col>
                <Col md="auto">
                    <ModalNewColab button="btn btn-falcon-primary" />
                </Col>
            </Row>
            <Row>
                {
                    workers?.map(u => {
                        return (
                            <Col lg="3" md="4" className="mb-3" key={u?.id}>
                                <Card className="text-center h-100">
                                    <Card.Body>
                                        <Link to={`/empresa/colaboradores/${u?.id}`}>
                                            <AvatarName size="4xl" {...u} />
                                            <h6 className="mb-1">
                                                {u?.name}
                                            </h6>
                                            <p className="fs--2 mb-1 text-700">
                                                {u?.email}
                                                {u?.job ? <><br />{u?.job}</> : ''}
                                                <br />
                                                <span className={`badge badge-subtle-${u?.active ? 'success' : 'danger'}`}>{u?.active ? 'Ativo' : 'Inativo'}</span>
                                            </p>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </>
    );
}


export default Colaboradores;