import React from "react";
import Badge from 'react-bootstrap/Badge';


export const BadgeActive = props => {

    const label = props?.label || props?.name || 'Ativo';
    if (!label) return "<!-- Label não definido-->";

    return (
        <Badge bg="success">{label}</Badge>
    );
}

export const BadgeInactive = props => {

    const label = props?.label || props?.name || 'Inativo';
    if (!label) return "<!-- Label não definido-->";

    return (
        <Badge bg="danger">{label}</Badge>
    );
}
