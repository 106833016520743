import React, { useState } from "react";
import FormPadrao from "../../../utils/form";
import { mutate } from 'swr';
import { Button, Modal } from "react-bootstrap";
import useLoadData from "../../../../data/swr/useLoadData";
import RequestError from "../../../utils/request/RequestError";
import RequestLoading from "../../../utils/request/RequestLoading";


const ModalNavEdit = props => {

    const nav = props?.nav || null;
    const md = props?.md || null;

    const [modalShow, setModalShow] = useState(false);
    const { data, error, isLoading } = useLoadData(modalShow ? `/adm/navs?nav=${nav}` : null);

    function onSubmitSuccess() {
        setModalShow(false);
        mutate(`/adm/navs?nav=${nav}`);
        if (!md && nav) mutate('/adm/navs?type=default');
        if (md) mutate(`/adm/modules/${md}`);
    }

    const formEdit = {
        // formType: 'prepend',
        action: '/adm/navs/' + nav,
        method: 'PUT',
        onSubmitSuccess,
        data: data,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: '12', required: 'Nome não informado!', autoFocus: true },
            { name: 'path', type: 'text', label: 'Link', col: '6', required: 'Link não informado!' },
            { name: 'parent', type: 'text', label: 'ID Superior', col: '2' },
            { name: 'show', type: 'select', label: 'Exibir', col: '2', options: [{ label: 'Sim', value: true }, { label: 'Não', value: false }] },
            { name: 'or', type: 'text', label: 'Ordem Exibição', col: '2' },
            { name: 'description', type: 'textarea', label: 'Descrição', col: '12' },
        ],
        submits: [
            { label: 'Atualizar', class: "btn btn-primary" }
        ],
    };

    // SE FOR MENUS DE NAVEGAÇÃO PADRÃO PRECISA DE ICONES
    if (!md) formEdit.fields.splice(5, 0, { name: 'icon', type: 'text', label: 'Icone', col: '12', required: 'Icone não informado!' });

    // CAMPOS HIDDEN
    if (md) formEdit.fields.push({ name: 'module', type: 'hidden', value: md });
    if (nav) formEdit.fields.push({ name: 'id', type: 'hidden', value: nav });

    return (
        <>
            {
                props?.type === "dropdown" ? (
                    <Button className="dropdown-item" variant="" onClick={() => setModalShow(true)}>
                        Editar
                    </Button>
                ) : (
                    <Button className={"btn btn-link p-0 m-0 ms-2"} variant="" onClick={() => setModalShow(true)}>
                        <span className="m-0 p-0">{props?.name}</span>
                    </Button>
                )
            }
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <div className="">
                        <h4 className="mb-1" id="staticBackdropLabel">Novo Menu</h4>
                        <p className="fs--2 mb-0">Menu principal</p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {error && <RequestError error={error} />}
                    {isLoading && <RequestLoading />}
                    {!isLoading && !error && < FormPadrao {...formEdit} />}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalNavEdit;