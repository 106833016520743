import React, { useState } from "react";
import { Card, Col, Row } from 'react-bootstrap';
import useLoadData from "../../../../data/swr/useLoadData";
import RequestError from "../../../utils/request/RequestError";
import RequestLoading from "../../../utils/request/RequestLoading";
import ModalNewJobs from "./ModalNewJobs";
import Tree from 'react-d3-tree';
import AvatarName from "../../../adm/utils/AvatarName";
import ModalEditJobs from "./ModalEditJobs";

const Cargos = props => {

    const [typeView, setTypeView] = useState('grid');
    const { data, error, isLoading } = useLoadData('/empresa/cargos');
    const jobs = data?.jobs;

    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    if (!jobs?.grid?.length) return (
        <Row className="justify-content-center">
            <Col lg="6">
                <Card>
                    <Card.Body className=" p-4 p-sm-5">
                        <div className="text-center">
                            <span className="fw-black lh-1 text-300 fs-error">
                                <span className="fas fa-briefcase"></span>
                            </span>
                            <p className="mt-3">Não há cargos cadastrados no momento. <br /> Por favor, cadastre um novo setor para continuar.</p>
                            <ModalNewJobs />
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
    return (
        <>
            <Row className="mb-3 justify-content-between">
                <Col md="auto">
                    <h4 className="m-0 d-none">Cargos<span className="fw-normal ms-1">({jobs?.length})</span></h4>
                </Col>
                <Col md="auto">
                    <ModalNewJobs button="btn btn-falcon-primary" />
                    <button type="button" className={`btn btn-falcon-default ms-1 ${typeView === "tree" ? 'active' : ''}`} onClick={() => setTypeView('tree')}><span className="fas fa-sitemap"></span></button>
                    <button type="button" className={`btn btn-falcon-default ms-1 ${typeView === "grid" ? 'active' : ''}`} onClick={() => setTypeView('grid')}><span className="fas fa-th"></span></button>
                </Col>
            </Row>
            {
                typeView === "tree" ? (
                    <ViewTree tree={jobs?.tree} />
                ) : (
                    <ViewGrid grid={jobs?.grid} />
                )
            }
        </>
    );
}

export default Cargos;


const ViewGrid = props => {

    const grid = props?.grid;
    return (
        <Row>
            {
                grid?.map(s => (
                    <Col key={s?.id} lg="4" md="6" className="mb-3">
                        <Card className="h-100 hover-actions-trigger">
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-2 lh-sm flex-1 me-5">
                                        {s?.name}
                                        {s?.sector ? <><br /><small>{s?.sector}</small></> : ""}
                                    </h5>
                                    <div className="hover-actions top-0 end-0 mt-4 me-4">
                                        <ModalEditJobs id={s?.id} />
                                    </div>
                                </div>
                                <span className={`badge badge-subtle-${s?.active ? 'success' : 'danger'}`}>{s?.active ? 'Ativo' : 'Inativo'}</span>
                                <div className="d-flex align-items-center justify-content-between">
                                    {!!!s?.users?.length && <small className="mt-3"><i>Sem Usuário vinculado</i></small>}
                                    <ul className="nav avatar-group mb-0 d-none d-md-flex mt-2">
                                        {
                                            s?.users?.map((u, i) => {
                                                return (
                                                    <li className="nav-item" key={i}>
                                                        <AvatarName {...u} />
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    <div className="">
                                        <span className="fas fa-users"></span> {s?.users?.length}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))
            }
        </Row>
    );
}

const ViewTree = props => {

    const tree = props?.tree;

    return (
        <div id="treeWrapper" style={{ width: '100%', height: '500px' }} className="border">
            <Tree
                data={{ ...tree }}
                translate={{ x: 100, y: 300 }}
                renderCustomNodeElement={({ nodeDatum, toggleNode }) => (
                    <g>
                        <circle r="5" onClick={toggleNode}>
                            <title>{nodeDatum.name}</title>
                        </circle>
                        <text fill="black" strokeWidth="1" fontSize="12px" x="10" dy="-5" onClick={() => console.log(nodeDatum)}>
                            {nodeDatum.name}
                        </text>
                        {nodeDatum?.sector && (
                            <text fill="black" x="10" dy="10" fontSize="9px" strokeWidth="1">
                                Setor: {nodeDatum?.sector}
                            </text>
                        )}
                    </g>
                )}
            />
        </div>
    );
}