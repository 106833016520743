import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../../data/axios/api";
import ShowError from "../utils/request/ShowError";


const SetPasswordForm = props => {

    const { register, formState: { errors }, handleSubmit, setError } = useForm();
    const [disabled, setDisabled] = useState(false);
    const navegate = useNavigate();
    const { search } = useLocation();

    async function onSubmit(formData) {
        try {
            setDisabled(true);
            await api.post('/novo-acesso' + search, { ...formData });
            navegate('/login');
            setDisabled(false);
        } catch (error) {
            setDisabled(false);
            if (error?.response?.data?.error)
                return setError(error?.response?.data?.error.name, { type: 'custom', message: error?.response?.data?.error.error });
            return ShowError(error);
        }
    }

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Senha</label>
                <input type="password" id="password" className="form-control" {...register("password", { required: true })} disabled={disabled} />
                {errors.password?.type === "required" && <small className="text-danger">Informe sua Senha</small>}
                {errors?.password?.type === "custom" && <small className="text-danger">{errors?.password?.message}</small>}
            </div>
            <div className="mb-3">
                <label htmlFor="password2" className="form-label">Confirmação de Senha</label>
                <input type="password" id="password2" className="form-control" {...register("password2", { required: true })} disabled={disabled} />
                {errors.password2?.type === "required" && <small className="text-danger">Informe sua Senha</small>}
                {errors?.password2?.type === "custom" && <small className="text-danger">{errors?.password2?.message}</small>}
            </div>
            <div className="d-grid">
                <button type="submit" disabled={disabled} className="btn btn-primary">
                    {disabled ?
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </> : 'Definir'
                    }
                </button>
            </div>
        </form>
    );
}


export default SetPasswordForm;