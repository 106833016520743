import { io } from 'socket.io-client';

var baseURL = 'http://localhost:3001';
const hostname = document.location.hostname;
const dev = hostname?.indexOf('localhost') > -1;
if (!dev) baseURL = `https://esv-api.controleplus.com.br`;
const nameSchema = process.env.REACT_APP_NAMESCHEMA;
const schema = localStorage.getItem(nameSchema);

const nameToken = process.env.REACT_APP_NAMETOKEN;
const token = localStorage.getItem(nameToken);

const socket = io(baseURL, {
  auth: {
    token: token,
  },
  query: {
    schema: schema,
  },
});

export default socket;
