import React, { useContext } from "react";
import AppContext from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";


const AcessSchemaClient = props => {


    const { contextValue, setContextValue } = useContext(AppContext);
    const navigate = useNavigate();
    const schema = props?.schema;
    if (!schema) return ('<!--Sem Schema-->');

    async function setSchema(schema) {
        if (!schema) return false;
        localStorage.setItem(process.env.REACT_APP_NAMESCHEMA, schema);
        setContextValue({ ...contextValue, schema });
        navigate('/');
        mutate('/navigation'); 
    }

    return (
        <button className={props?.className ? props?.className : ''} type="button" onClick={() => setSchema(schema)}>
            {props?.label ? props?.label : <>Acessar <small className="fas fa-external-link-alt"></small></>}
        </button>
    );
}

export default AcessSchemaClient;