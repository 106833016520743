import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TypeTemplate from './type-message/Template';
import { useParams } from 'react-router-dom';
import useLoadData from '../../../../../data/swr/useLoadData';
import RequestError from '../../../../utils/request/RequestError';
import RequestLoading from '../../../../utils/request/RequestLoading';

const BodyChatTemplates = (props) => {
  const params = useParams();
  const [templates, setTemplates] = useState([]);
  const { data, error, isLoading } = useLoadData(`/m3002/${params.md}/chat/templates`);

  useEffect(() => {
    if (data && !data.error) setTemplates([...data]);
    console.log(data);
  }, [data]);

  if (error) return <RequestError b="0" error={error} />;
  if (isLoading) return <RequestLoading />;
  return (
    <div className="p-2">
      <Row>
        {templates?.map((template) => (
          <Col lg="auto" key={template.id}>
            <TypeTemplate template={template} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BodyChatTemplates;
