import cryptoMd5 from 'crypto-js/md5';
import api from '../data/axios/api';
import { toast } from 'react-toastify';

export const formatCPF = (cpf) => {
  if (!cpf) return '';
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  return cpf;
};

export const formatTelefone = (telefone) => {
  if (!telefone) return '';
  telefone = telefone.replace(/\D/g, '');

  if (telefone.length === 11) {
    telefone = telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  } else {
    telefone = telefone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }
  return telefone;
};
export const md5 = (str = new Date()) => {
  return cryptoMd5(str);
};
export const formatCNPJ = (cnpj) => {
  if (!cnpj) return '';
  cnpj = cnpj.replace(/[^\d]/g, '');
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const onFormSubmit = async ({ action = '', method = '', data = {}, onSubmitSuccess = () => true, onSubmitError = () => true }) => {
  try {
    switch (method) {
      case 'POST':
        await api.post(action, data);
        break;
      case 'PUT':
        await api.put(action, data);
        break;
      case 'DELETE':
        await api.delete(action);
        break;
      default:
        break;
    }

    toast.success(<span style={{ fontSize: '15px' }}>Operação realizada com sucesso!</span>, {
      position: 'bottom-left',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: localStorage.getItem('data-bs-theme') || 'light',
      toastId: 'toast-ok',
      // icon: ({ theme, type }) => <img />
    });

    if (typeof onSubmitSuccess === 'function') onSubmitSuccess();
    return true;
  } catch (error) {
    if (typeof onSubmitError === 'function') onSubmitError();
    const errorResponse = error?.response?.data || {};
    const errorName = errorResponse.error?.name;
    const errorMessageName = errorResponse.error?.message?.name;
    const errorMessageError = errorResponse.error?.message?.error;
    if (errorName) return toast.error(errorName);
    if (errorMessageError) return toast.error(errorMessageName + ' - ' + errorMessageError);
    return toast.error('Algo não saiu como esperado! Tente novamente!');
  }
};
