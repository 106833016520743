import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';

import MainLayoutAPP from '../components/app';
import MainLayoutAdm from '../components/adm';
import Erro404 from '../components/errors/404';
import LayoutLogin from '../components/autenticacao/LayoutLogin';
import LayoutDefinedPassHash from '../components/autenticacao/LayoutDefinedPassHash';
import DashboardADM from '../components/adm/dashboard';
import AppContext from '../context/Context';
import UsersADM from '../components/adm/users';
import ModulesADM from '../components/adm/modules';
import Clients from '../components/adm/clients';
import ClientById from '../components/adm/clients/ClientById';
import LayoutEmpresa from '../components/app/empresa';
import Cadastro from '../components/app/empresa/Cadastro';
import Setores from '../components/app/empresa/setores';
import Cargos from '../components/app/empresa/cargos';
import Colaboradores from '../components/app/empresa/colaboradores';
import ModuleById from '../components/adm/modules/ModuleById';
import NavegatorDefaultADM from '../components/adm/nav';
import ColaboradorById from '../components/app/empresa/colaboradores/ColaboradorById';
import LayoutFinanceiro from '../components/app/modulos/3001-financeiro';
import LayoutFornecedores from '../components/app/modulos/3001-financeiro/fornecedores';
import LayoutContasPagar from '../components/app/modulos/3001-financeiro/contas-pagar';
import LayoutContasReceber from '../components/app/modulos/3001-financeiro/contas-receber';
import LayoutConfiguracoes from '../components/app/modulos/3001-financeiro/configuracoes';
import TabelaFornecedores from '../components/app/modulos/3001-financeiro/fornecedores/TabelaFornecedores';
import LayoutCadastroFornecedor from '../components/app/modulos/3001-financeiro/fornecedores/cadastro';
import HomeFornecedor from '../components/app/modulos/3001-financeiro/fornecedores/cadastro/Home';
import DadosFornecedor from '../components/app/modulos/3001-financeiro/fornecedores/cadastro/Dados';
import LayoutChatWpp from '../components/app/modulos/3002-chatwpp';
import LayoutChatWppConfig from '../components/app/modulos/3002-chatwpp/configuracao';
import ChatWppConfigContact from '../components/app/modulos/3002-chatwpp/configuracao/contato';
import LayoutChatWppContact from '../components/app/modulos/3002-chatwpp/contato';
import ChatWppContactList from '../components/app/modulos/3002-chatwpp/contato/list';
import ChatWppContactDetails from '../components/app/modulos/3002-chatwpp/contato/list/details';
import LayoutChatWppChat from '../components/app/modulos/3002-chatwpp/chat';
import ChatWppChatBodyContent from '../components/app/modulos/3002-chatwpp/chat/BodyChat';
import BodyChatMessage from '../components/app/modulos/3002-chatwpp/chat/BodyChatMessage';
import BodyChatTemplates from '../components/app/modulos/3002-chatwpp/chat/BodyChatTemplates';
import BodyChatMain from '../components/app/modulos/3002-chatwpp/chat/BodyChatMain';

const Layout = () => {
  const { contextValue } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const TagHTML = document.getElementsByTagName('html')[0];

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
    HTMLClassList.add('fontawesome-i2svg-active');
    HTMLClassList.add('fontawesome-i2svg-complete');

    const theme = localStorage.getItem('data-bs-theme') || 'light';
    TagHTML.setAttribute('data-bs-theme', theme);
    TagHTML.setAttribute('lang', 'pt-BR');
    TagHTML.setAttribute('dir', 'ltr');
  }, [HTMLClassList, TagHTML]);

  // console.log('contextValue', contextValue);
  if (!contextValue.isAuthenticated) {
    // console.log('contextValue.isAuthenticated', contextValue.isAuthenticated);
    return (
      <>
        <Routes>
          <Route path="*" element={<LayoutLogin />} />
        </Routes>
      </>
    );
  }

  /* Start Layout do ADMIN */
  if (!contextValue?.schema) {
    return (
      <>
        <Routes>
          <Route path="/login" element={<LayoutLogin />} />
          <Route path="/novo-acesso" element={<LayoutDefinedPassHash />} />

          <Route element={<MainLayoutAdm />}>
            <Route exact path="/" element={<DashboardADM />} />
            <Route exact path="/adm/dashboard" element={<DashboardADM />} />
            <Route exact path="/adm/usuarios" element={<UsersADM />} />

            <Route exact path="/adm/navegacao-padrao" element={<NavegatorDefaultADM />} />

            <Route exact path="/adm/modulos" element={<ModulesADM />} />
            <Route exact path="/adm/modulos/:cid" element={<ModuleById />} />
            <Route exact path="/adm/clientes" element={<Clients />} />
            <Route exact path="/adm/clientes/:cid" element={<ClientById />} />
          </Route>

          <Route path="*" element={<Erro404 />} />
        </Routes>
      </>
    );
    /* End Layout do ADMIN */
  } else {
    /* Start Layout do APP */
    return (
      <>
        <Routes>
          <Route path="/login" element={<LayoutLogin />} />
          <Route path="/novo-acesso" element={<LayoutDefinedPassHash />} />

          {/* LAYOUT DO APP */}
          <Route element={<MainLayoutAPP />}>
            <Route exact path="/" element={<DashboardADM />} />

            {/* LAYOUT PADRÃO DA EMPRESA */}
            <Route element={<LayoutEmpresa />}>
              <Route exact path="/empresa/cadastro" element={<Cadastro />} />
              <Route exact path="/empresa/setores" element={<Setores />} />
              <Route exact path="/empresa/cargos" element={<Cargos />} />
              <Route exact path="/empresa/colaboradores" element={<Colaboradores />} />
              <Route exact path="/empresa/colaboradores/:cid" element={<ColaboradorById />} />
            </Route>

            {/* MÓDULOS */}
            {/* CHAT */}
            <Route element={<LayoutChatWpp />}>
              <Route element={<LayoutChatWppConfig />}>
                <Route exact path="/m3002/:md/config" element={<ChatWppConfigContact />} />
                <Route exact path="/m3002/:md/config/conta" element={<ChatWppConfigContact />} />
              </Route>
              <Route element={<LayoutChatWppContact />}>
                <Route exact path="/m3002/:md/contact" element={<ChatWppContactList />} />
                <Route exact path="/m3002/:md/contact/list" element={<ChatWppContactList />} />
                <Route exact path="/m3002/:md/contact/:hash" element={<ChatWppContactDetails />} />
              </Route>

              <Route element={<LayoutChatWppChat />}>
                <Route element={<ChatWppChatBodyContent />}>
                  <Route exact path="/m3002/:md/chat" element={<BodyChatMain />} />
                  <Route exact path="/m3002/:md/chat/:hash" element={<BodyChatMessage />} />
                  <Route exact path="/m3002/:md/chat/:hash/template" element={<BodyChatTemplates />} />
                </Route>
              </Route>
            </Route>

            {/* FINANCEIRO */}
            <Route element={<LayoutFinanceiro />}>
              {/* FORNECEDORES */}
              <Route element={<LayoutFornecedores />}>
                <Route exact path="/m3001/:md/gestao-contas/fornecedor" element={<TabelaFornecedores />} />
                <Route element={<LayoutCadastroFornecedor />}>
                  <Route exact path="/m3001/:md/gestao-contas/fornecedor/:fid" element={<HomeFornecedor />} />
                  <Route exact path="/m3001/:md/gestao-contas/fornecedor/:fid/dados" element={<DadosFornecedor />} />
                  <Route exact path="/m3001/:md/gestao-contas/fornecedor/:fid/cartoes" element={<DadosFornecedor />} />
                </Route>
              </Route>
              {/* CONTAS A PAGAR */}
              <Route element={<LayoutContasPagar />}>
                <Route exact path="/m3001/:md/gestao-contas/contas-pagar" element={<Cadastro />} />
                <Route exact path="/m3001/:md/gestao-contas/contas-pagar/:cid" element={<Cadastro />} />
              </Route>
              {/* CONTAS A RECEBER */}
              <Route element={<LayoutContasReceber />}>
                <Route exact path="/m3001/:md/gestao-contas/contas-receber" element={<Cadastro />} />
                <Route exact path="/m3001/:md/gestao-contas/contas-receber/:cid" element={<Cadastro />} />
              </Route>
              {/* CONFIGURAÇÕES */}
              <Route element={<LayoutConfiguracoes />}>
                <Route exact path="/m3001/:md/configuracoes" element={<Cadastro />} />
              </Route>
            </Route>
          </Route>

          <Route path="*" element={<Erro404 />} />
        </Routes>
      </>
    );
    /* End Layout do APP */
  }
};

export default Layout;
