import React from 'react';
import { Link } from 'react-router-dom';

const DropdownCustom = (props) => {
  return (
    <div className="dropdown font-sans-serif position-static">
      <button
        className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
        type="button"
        data-bs-toggle="dropdown"
        data-boundary="window"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className={`${props.icon ? props.icon : 'fas fa-ellipsis-h'}  fs--1`}></span>
      </button>
      <div className="dropdown-menu dropdown-menu-end border py-0">
        <div className="py-2">{props.children}</div>
      </div>
    </div>
  );
};

const DropdownCustomItem = (props) => (
  <button className="dropdown-item" {...props}>
    {props.children}
  </button>
);
const DropdownCustomLink = (props) => (
  <Link className="dropdown-item" {...props}>
    {props.children}
  </Link>
);

DropdownCustom.Item = DropdownCustomItem;
DropdownCustom.Button = DropdownCustomItem;
DropdownCustom.Link = DropdownCustomLink;
export default DropdownCustom;
