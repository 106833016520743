import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import RequestError from '../../../../utils/request/RequestError';
import RequestLoading from '../../../../utils/request/RequestLoading';
import useLoadData from '../../../../../data/swr/useLoadData';
import HeaderChatMessage from './HeaderChatMessage';
import MsgText from './type-message/MsgText';
import { Link } from 'react-router-dom';
import socket from '../../../../../data/socket';
import AudioRecorder from './type-message/AudioRecorder';

const ChatWppChatBodyContent = (props) => {
  const params = useParams();

  const { data, error, isLoading } = useLoadData(`/m3002/${params.md}/chat/contact/${params.hash ? params.hash : 'new'}`);
  const [contact, setContact] = useState({});
  useEffect(() => {
    setContact(data);
  }, [data]);

  useEffect(() => {
    const updateContact = (body) => {
      if (body && body.hash === params.hash) {
        setContact(body);
      }
    };
    socket.on('wpp_contact', updateContact);
    return () => {
      socket.off('wpp_contact', updateContact);
    };
  }, [params.hash]);

  if (error) return <RequestError b="0" error={error} />;
  if (isLoading) return <RequestLoading />;
  return (
    <React.Fragment>
      <div className="card-chat-pane active show">
        {params.hash && (
          <div className="chat-content-header">
            <HeaderChatMessage contact={contact}>
              <Link className="btn btn-falcon-primary" to={{ pathname: `/m3002/${params.md}/chat/${params.hash}/template` }}>
                <span className="fas fa-list-alt"></span>
              </Link>
            </HeaderChatMessage>
          </div>
        )}
        <div className="chat-content-body" style={{ display: 'inherit' }}>
          <div className="" style={{ height: '100%' }}>
            <div className="chat-content-scroll-area scrollbar">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      {contact && contact.is_session_active && (
        <div className="chat-editor-area">
          <MsgText />
          <AudioRecorder contact={contact} />
        </div>
      )}
    </React.Fragment>
  );
};

export default ChatWppChatBodyContent;
