import React from 'react';
// import AppContext from "../../../context/Context";

const RequestError = (props) => {
  // const { setContextValue } = useContext(AppContext);

  const error = props?.error;
  const status = error?.response?.data?.status || error?.response?.status;
  const msg =
    error?.response?.data?.error?.error ||
    error?.response?.data?.error?.message ||
    error?.response?.data?.error ||
    error?.response?.statusText ||
    error?.message;
  const isExpired = Number(status) === 401;
  if (isExpired) {
    console.log('isExpired', isExpired);
    document.location.href = '/login';
    // localStorage.removeItem(process.env.REACT_APP_NAMETOKEN);
    // return setContextValue({ isAuthenticated: false });
  }

  return (
    <>
      <div className="h-100 w-100 py-3">
        <div className={`h-100 flex-wrap d-flex align-items-center justify-content-center rounded border border-${props.b ? props.b : '1'} p-3`}>
          <div className="fw-black lh-1 text-300 fs-error-- fs-5 text-center">
            {status}{' '}
            {!!msg && (
              <>
                <br />
                <small className="fs-1">{msg}</small>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestError;
