import React from "react";


const RequestLoading = props => {

    return (
        <div className="h-100 w-100 py-3">
            <div className="h-100 d-flex align-items-center justify-content-center bg-300  text-900 rounded p-3">
                <span className="fa fa-spinner fs-5 fa-pulse"></span>
            </div>
        </div>
    );
}

export default RequestLoading;