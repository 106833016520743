import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../../data/axios/api";
import ShowError from "../../utils/request/ShowError";
import { Button, Form, Modal } from "react-bootstrap";
import { mutate } from "swr";


const ModalUserDelete = props => {

    const uid = props?.uid;
    const [disabled, setDisabled] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const { handleSubmit } = useForm();


    async function onSubmit() {
        try {
            setDisabled(true);
            await api.delete('/adm/users/' + uid)
            setDisabled(false);
            setModalShow(false);
            mutate('/adm/users');
        } catch (error) {
            setDisabled(false);
            return ShowError(error);
        }
    }

    return (
        <>
            <Button className="dropdown-item text-danger" variant="" onClick={() => setModalShow(true)}>
                Excluir
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="md"
            >
                <Modal.Body>
                    <div className="p-4 pb-0">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <h5 className="mb-2 fs-0">
                                Atenção!
                            </h5>
                            <p>
                                Esta ação não pode ser desfeita. <br />
                                Tem certeza de que deseja continuar?
                            </p>
                            <div className="mb-2 text-end mt-3">
                                <button className="btn btn-falcon-primary me-1 mb-1" type="button" onClick={() => setModalShow(false)}>Cancelar</button>
                                <button className="btn btn-falcon-danger me-0 mb-1" type="submit">
                                    Excluir
                                    {disabled ?
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </> :
                                        <>
                                            <span className="fas fa-trash ms-1" data-fa-transform="shrink-3"></span>
                                        </>
                                    }
                                </button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalUserDelete;