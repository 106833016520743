import React, { useState } from "react";
import FormPadrao from "../../../utils/form";
import { mutate } from 'swr';
import { Button, Modal } from "react-bootstrap";


const ModalNavAdd = props => {

    const [modalShow, setModalShow] = useState(false);

    const md = props?.md || null;
    const nav = props?.nav || null; // parent

    function onSubmitSuccess() {
        setModalShow(false);
        mutate('/adm/navs');
        if (!md && !nav) mutate('/adm/navs?type=default');
        if (md) mutate(`/adm/modules/${md}`);
    }

    const formEdit = {
        // formType: 'prepend',
        action: '/adm/navs',
        method: 'POST',
        onSubmitSuccess,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: '12', required: 'Nome não informado!', autoFocus: true },
            { name: 'path', type: 'text', label: 'Link', col: '6', required: 'Link não informado!' },
            { name: 'show', type: 'select', label: 'Exibir', col: '3', options: [{ label: 'Sim', value: true }, { label: 'Não', value: false }] },
            { name: 'or', type: 'text', label: 'Ordem Exibição', col: '3', required: 'Descrição não informado!' },
            { name: 'description', type: 'textarea', label: 'Descrição', col: '12', required: 'Descrição não informado!' },
        ],
        submits: [
            { label: 'Cadastrar', class: "btn btn-primary" }
        ],
    };

    // SE FOR MENUS DE NAVEGAÇÃO PADRÃO PRECISA DE ICONES
    if (!md && !nav) formEdit.fields.splice(4,0,{ name: 'icon', type: 'text', label: 'Icone', col: '12', required: 'Icone não informado!' });
    
    // CAMPOS HIDDEN
    if (md) formEdit.fields.push({ name: 'module', type: 'hidden', value: md });
    if (nav) formEdit.fields.push({ name: 'parent', type: 'hidden', value: nav });

    return (
        <>
            <Button className={nav ? "btn btn-link p-0 m-0 ms-2" : "btn btn-falcon-primary btn-sm"} variant="" onClick={() => setModalShow(true)}>
                <span className="fas fa-plus"></span>
                <span className="d-none d-sm-inline-block ms-1">{nav ? "" : "Novo"}</span>
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <div className="">
                        <h4 className="mb-1" id="staticBackdropLabel">Novo Menu</h4>
                        <p className="fs--2 mb-0">Menu principal</p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalNavAdd;