import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../../data/axios/api";
import ShowError from "../utils/request/ShowError";
import AppContext from "../../context/Context";
import jwt_decode from 'jwt-decode';


const LoginForm = props => {

    const { register, formState: { errors }, handleSubmit, setError } = useForm();
    const { setContextValue } = useContext(AppContext);
    const [disabled, setDisabled] = useState(false);
    const navegate = useNavigate();

    async function onSubmit(formData) {
        try {
            setDisabled(true);
            const { data } = await api.post('/login', { ...formData });
            if (data?.token) {
                const payload = jwt_decode(data?.token);
                localStorage.setItem(process.env.REACT_APP_NAMETOKEN, data.token);
                var schema = localStorage.getItem(process.env.REACT_APP_NAMESCHEMA);
                switch (true) {
                    case !schema && payload?.schema:
                        schema = payload?.schema;
                        localStorage.setItem(process.env.REACT_APP_NAMESCHEMA, schema);
                        break;
                    case schema && !payload?.schema:
                        payload.schema = schema;
                        localStorage.setItem(process.env.REACT_APP_NAMESCHEMA, schema);
                        break;
                    default:
                        break;
                }
                setContextValue({ ...payload, token: data?.token, isAuthenticated: true });
                return navegate('/');
            }

        } catch (error) {
            setDisabled(false);
            console.log(error);
            if (error?.response?.data?.error?.message)
                return setError('password', { type: 'custom', message: error.response.data.error.message });
            return ShowError(error);
        }
    }

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
                <label htmlFor="email" id="email" className="form-label">Endereço de Email</label>
                <input type="text" className="form-control" {...register("username", { required: true, pattern: /^\S+@\S+$/i })} disabled={disabled} />
                {errors.username && <small className="text-danger">E-mail não informado ou inválido</small>}
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Senha</label>
                <input type="password" id="password" className="form-control" {...register("password", { required: true })} disabled={disabled} />
                {errors.password?.type === "required" && <small className="text-danger">Informe sua Senha</small>}
                {errors?.password?.type === "custom" && <small className="text-danger">{errors?.password?.message}</small>}
            </div>
            <div className="row flex-between-center mb-2">
                <div className="col-auto">
                    <div className="mb-0 form-check">
                        <input type="checkbox" className="form-check-input" id="rememberMe" name="rememberMe" />
                        <label className="form-check-label mb-0" htmlFor="rememberMe">Lembrar de Mim</label>
                    </div>
                </div>
                <div className="col-auto">
                    <Link className="fs--1">
                        Esqueceu sua senha
                    </Link>
                </div>
            </div>
            <div className="d-grid">
                <button type="submit" disabled={disabled} className="btn btn-primary">
                    {disabled ?
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </> : 'Entrar'
                    }
                </button>
            </div>
        </form>
    );
}


export default LoginForm;