import useSWR from 'swr';
import api from '../axios/api';
import AppContext from '../../context/Context';
import { useContext } from 'react';

const useLoadData = (url) => {
  const { contextValue } = useContext(AppContext);

  const fetcher = async (url) => {
    // const nameToken = process.env.REACT_APP_NAMETOKEN;
    // const token = localStorage.getItem(nameToken);
    // const schema = localStorage.getItem(nameSchema);
    const nameSchema = process.env.REACT_APP_NAMESCHEMA;
    const token = contextValue?.token;
    const schema = localStorage.getItem(nameSchema);

    const response = await api.get(url, { headers: { authorization: `Bearer ${token}`, schema } });

    // DEFININDO O TITULO DA PÁGINA
    if (response?.data?.title) document.title = `Painel - ${response.data.title}`;

    return response.data;
  };

  return useSWR(url, fetcher, { revalidate: false, provider: () => new Map() });
  // return useSWR(url, fetcher, { refreshInterval: 120000 });
};

export default useLoadData;
