import React, { useState } from "react";
import FormPadrao from "../../utils/form";
import { mutate } from 'swr';
import { Button, Modal } from "react-bootstrap";


const ModalUserAdd = props => {

    const [modalShow, setModalShow] = useState(false);

    function onSubmitSuccess() {
        setModalShow(false);
        mutate('/adm/users');
    }

    const formEdit = {
        // formType: 'prepend',
        action: '/adm/users',
        method: 'POST',
        onSubmitSuccess,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: '12', required: 'Nome não informado!', autoFocus: true },
            { name: 'surname', type: 'text', label: 'Apelido', col: '12' },
            { name: 'phone', type: 'tel', label: 'Telefone', col: '12', mask: "(99) 99999-9999", required: "Telefone não informado!" },
            { name: 'email', type: 'email', label: 'E-mail', col: '12', required: 'E-mail não informado!' },
        ],
        submits: [
            { label: 'Cadastrar', class: "btn btn-primary" }
        ],
    };

    return (
        <>
            <Button className="btn btn-falcon-primary" variant="" onClick={() => setModalShow(true)}>
                <span className="fas fa-plus"></span>
                <span className="d-none d-sm-inline-block ms-1">Novo</span>
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                size="md"
            >
                <Modal.Header closeButton>
                    <div className="">
                        <h4 className="mb-1" id="staticBackdropLabel">Novo Usuário</h4>
                        <p className="fs--2 mb-0 d-none-">Usuário administrador</p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalUserAdd;