import React, { useState } from "react";
import FormPadrao from "../../../utils/form";
import { mutate } from 'swr';
import { Button, Modal } from "react-bootstrap";


const ModalNavDelete = props => {

    const [modalShow, setModalShow] = useState(false);

    const md = props?.md;
    const nav = props?.nav;

    function onSubmitSuccess() {
        setModalShow(false);
        if (md) mutate(`/adm/modules/${md}`);
    }

    const formEdit = {
        // formType: 'prepend',
        action: '/adm/navs/' + nav,
        method: 'DELETE',
        onSubmitSuccess,
        fields: [
            {
                type: 'string', html: <>
                    <h5 className="mb-2 fs-0">
                        Atenção!
                    </h5>
                    <p>
                        Esta ação não pode ser desfeita. <br />
                        Tem certeza de que deseja continuar?
                    </p>
                </>
            }
        ],
        submits: [
            { label: 'Cancelar', class: "btn btn-falcon-primary", type: "button", onClick: () => setModalShow(false) },
            { label: 'Excluir', class: "btn btn-falcon-danger" },
        ],
    };

    return (
        <>
            <Button className={nav ? "btn btn-link p-0 m-0 ms-2" : "btn btn-falcon-primary btn-sm"} variant="" onClick={() => setModalShow(true)}>
                <span className="fas fa-trash-alt text-danger"></span>
                <span className="d-none d-sm-inline-block ms-1">{nav ? "" : "Novo"}</span>
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                size="md"
            >
                <Modal.Body className="p-4">
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalNavDelete;