import React from 'react';
import useLoadData from '../../../../../../data/swr/useLoadData';
import { useParams } from 'react-router-dom';
import RequestError from '../../../../../utils/request/RequestError';
import RequestLoading from '../../../../../utils/request/RequestLoading';
import { mutate } from 'swr';
import FormPadrao from '../../../../../utils/form';

const ChatWppConfigContact = (props) => {
  const params = useParams();
  const { data, error, isLoading } = useLoadData(`/m3002/${params.md}/config`);

  function onSubmitSuccess() {
    mutate(`/m3002/${params.md}/config`);
  }

  const optionsVersionApi = [{ value: 'v20.0', label: 'v20.0' }];

  const formEdit = {
    action: `/m3002/${params.md}/config`,
    method: 'PUT',
    data: data?.config,
    onSubmitSuccess,
    fields: [
      { name: 'verified_name', type: 'text', label: 'Nome', col: '12', disabled: true },
      { name: 'display_phone_number', type: 'text', label: 'Telefone', col: '6', disabled: true },
      { name: 'code_verification_status', type: 'text', label: 'Código Verificado', col: '6', disabled: true },
      { name: 'quality_rating', type: 'text', label: 'Avaliação', col: '6', disabled: true },
      { name: 'platform_type', type: 'text', label: 'Plataforma', col: '6', disabled: true },
      {
        name: 'ident_contact',
        type: 'text',
        label: 'Identificação do número de telefone',
        col: '12',
        required: 'Identificação do número de telefone não informado!',
        autoFocus: true,
      },
      {
        name: 'ident_account',
        type: 'text',
        label: 'Identificação da conta do WhatsApp Business',
        col: '12',
        required: 'Identificação da conta do WhatsApp Business não informado!',
      },
      { name: 'api_vesion', type: 'select', label: 'Versão da API', col: '12', options: optionsVersionApi, required: 'Versão da API não informado!' },
      { name: 'api_token', type: 'textarea', label: 'Token de Acesso', col: '12', required: 'Token de Acesso não informado!' },
    ],
    submits: [{ label: 'Salvar', class: 'btn btn-primary' }],
  };

  if (error) return <RequestError error={error} />;
  if (isLoading) return <RequestLoading />;
  return (
    <>
      <FormPadrao {...formEdit} />
    </>
  );
};

export default ChatWppConfigContact;
