import React, { useEffect, useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { useParams } from 'react-router-dom';
import socket from '../../../../../../data/socket';
import { ShowErrorSocket } from '../../../../../utils/request/ShowError';

const MsgText = (props) => {
  const [message, setMessage] = useState('');
  const params = useParams();
  const [contactHash, setContactHash] = useState(params.hash);
  const [modId, setModId] = useState(params.md);

  useEffect(() => {
    setContactHash(params.hash);
    setModId(params.md);
    socket.on('send_message_error', (err) => ShowErrorSocket(err));
    return () => {
      socket.off('send_message_error', (err) => ShowErrorSocket(err));
    };
  }, [params]);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.ctrlKey) {
      } else {
        event.preventDefault();
        handleSubmit({
          content: message,
          message_type: 'text',
          type: 'text',
          text: {
            preview_url: true,
            body: message,
          },
        });
      }
    }
  };

  const handleSubmit = async (formData) => {
    try {
      const options = {
        contact_hash: contactHash,
        mod_id: modId,
      };
      socket.emit('send_message', formData, options);
      setMessage('');
    } catch (error) {
      return false;
    }
  };

  return (
    <React.Fragment>
      <ReactTextareaAutosize
        className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
        value={message}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        minRows={1}
        maxRows={6}
        placeholder="Digite sua mensagem..."
        style={{ width: '100%', padding: '10px' }}
      />
      <button onClick={handleKeyDown} className="btn btn-sm btn-send-- shadow-none d-none">
        Enviar
      </button>
    </React.Fragment>
  );
};

export default MsgText;
