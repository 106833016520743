import React from "react";


const Table = props => {

    return (
        <div className="table-responsive scrollbar">
            <table className="table table-bordered table-striped fs--1 mb-0 ">
                {props.children}
            </table>
        </div>
    );
}

const TableHeader = props => {
    return <thead {...props} className="bg-200 text-900">{props.children}</thead>;
}
const TableBody = props => {
    return <tbody {...props} className="list">{props.children}</tbody>;
}

const TableTr = props => {
    return <tr {...props}>{props.children}</tr>;
}

const TableTh = props => {
    return <th {...props}>{props.children}</th>;
}
const TableTd = props => {
    return <td {...props} className="align-middle">{props.children}</td>;
}

Table.Header = TableHeader;
Table.Body = TableBody;

Table.Tr = TableTr;

Table.Th = TableTh;
Table.Td = TableTd;
export default Table;