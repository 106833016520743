import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useLoadData from "../../../../data/swr/useLoadData";
import RequestLoading from "../../../utils/request/RequestLoading";
import RequestError from "../../../utils/request/RequestError";
import AppContext from "../../../../context/Context";

const VerticalNav = props => {

    const { contextValue, setContextValue } = useContext(AppContext);
    const { data, error, isLoading } = useLoadData('/navigation');
    const { pathname } = useLocation();

    useEffect(() => {
        if (data?.allowed) {
            if (
                (data?.allowed && !contextValue?.allowed)
                ||
                (data?.allowed !== contextValue?.allowed)
            ) {
                setContextValue({ ...contextValue, allowed: data.allowed });
            }
        }
        // eslint-disable-next-line
    }, [data?.allowed]);





    if (isLoading) return <RequestLoading />;
    if (error) return <RequestError error={error} />;


    return (
        <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
            {data?.navigation?.map(m => {

                return (
                    <li className="nav-item" key={m?.id}>
                        <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                            <div className="col-auto navbar-vertical-label">{m?.name}</div>
                            <div className="col ps-0"><hr className="mb-0 navbar-vertical-divider" /></div>
                        </div>
                        {menu_tree(m?.menus, pathname)}
                    </li>
                );
            })}
        </ul>
    )
}


export default VerticalNav;



function menu_tree(m, pathname) {
    const mp = m?.map((menu, index) => {
        const idUnique = 'nav-' + menu?.name?.replace(/[ ]/g, '-').toLowerCase();
        const ul = (
            <React.Fragment key={menu?.id}>
                {
                    menu?.menus?.length ? (
                        <>
                            <Link to={`#${idUnique}`} className={`nav-link dropdown-indicator`} data-bs-toggle="collapse" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    {menu?.icon ? (
                                        <span className={`nav-link-icon`}>
                                            <span className={menu?.icon}></span>
                                        </span>
                                    ) : ""}
                                    <span className="nav-link-text ps-1">{menu?.name}</span>
                                </div>
                            </Link>
                            <ul className="nav collapse" id={idUnique} >
                                <li className="nav-item" >
                                    {menu_tree(menu.menus, pathname)}
                                </li>
                            </ul>
                        </>
                    ) : (
                        <Link to={menu?.path} className={`nav-link ${pathname === menu?.path ? 'active' : ''}`}>
                            <div className="d-flex align-items-center">
                                {menu?.icon ? (
                                    <span className={`nav-link-icon`}>
                                        <span className={menu?.icon}></span>
                                    </span>
                                ) : ""}
                                <span className="nav-link-text ps-1">{menu?.name}</span>
                            </div>
                        </Link>
                    )
                }
            </React.Fragment>
        );

        return ul;
    });
    return mp;
}