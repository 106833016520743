import React from "react";
import { Outlet } from 'react-router-dom';


const LayoutConfiguracoes = props => {
    return (
        <>
            <div>Configurações</div>
            <div className="layout-container-configuracoes">
                <Outlet />
            </div>
        </>
    );
}

export default LayoutConfiguracoes;