import React, { useRef } from "react";
import useLoadData from "../../../data/swr/useLoadData";
import RequestError from "../../utils/request/RequestError";
import RequestLoading from "../../utils/request/RequestLoading";
import FormPadrao from "../../utils/form";
import { Card, Col, Row } from "react-bootstrap";
import useScrollSpy from 'react-use-scrollspy';
import { mutate } from "swr";

const Cadastro = props => {

    const { data, error, isLoading } = useLoadData('/empresa/cadastro');
    const company = data?.company;

    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -150
    });

    function onSubmitSuccess() {
        mutate('/empresa/cadastro');
        mutate('/empresa/layout');
    }

    const formEdit = {
        formType: 'prepend',
        action: `/empresa/cadastro`,
        method: 'PUT',
        data: company,
        onSubmitSuccess,
        groups: [
            {
                id: "info",
                ref: sectionRefs[0],
                header: 'Informações Básicas',
                fields: [
                    { name: 'name', type: 'text', label: 'Razão Social', required: 'Razão Social não informado!', autoFocus: true },
                    { name: 'fantasy', type: 'text', label: 'Nome Fantasia', required: 'Nome não informado!' },
                    { name: 'document_number', type: 'text', label: 'Documento', mask: '99.999.999/9999-99', disabled: true },
                    { name: 'registration_number', type: 'text', label: 'Inscrição Estadual' },
                    { name: 'activity_primary', type: 'text', label: 'Atividade Principal' },
                    { name: 'phone', type: 'text', label: 'Telefone', mask: '(99) 99999-9999' },
                    { name: 'email', type: 'email', label: 'E-mail' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
            {
                id: "endereco",
                ref: sectionRefs[1],
                header: 'Endereço',
                fields: [
                    { name: 'postal_code', type: 'text', label: 'CEP', mask: '99999-999', required: 'CEP não informado!' },
                    { name: 'city', type: 'text', label: 'Cidade' },
                    { name: 'uf', type: 'text', label: 'Estado' },
                    { name: 'country', type: 'text', label: 'País' },
                    { name: 'district', type: 'text', label: 'Bairro' },
                    { name: 'number', type: 'text', label: 'Número' },
                    { name: 'ibge', type: 'number', label: 'IBGE' },
                    { name: 'address', type: 'text', label: 'Endereço' },
                    { name: 'complement', type: 'textarea', label: 'Complemento' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
            {
                id: "responsavel",
                ref: sectionRefs[2],
                header: 'Responsável',
                fields: [
                    { name: 'responsible_name', type: 'text', label: 'Nome' },
                    { name: 'responsible_position', type: 'text', label: 'Cargo' },
                    { name: 'responsible_phone', type: 'text', label: 'Telefone', mask: '(99) 99999-9999' },
                    { name: 'responsible_email', type: 'email', label: 'E-mail' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
            {
                id: "financeiro",
                ref: sectionRefs[3],
                header: 'Financeiro',
                fields: [
                    { name: 'finances_name', type: 'text', label: 'Nome' },
                    { name: 'finances_phone', type: 'text', label: 'Telefone', mask: '(99) 99999-9999' },
                    { name: 'finances_email', type: 'email', label: 'E-mail', required: 'E-mail não informado!' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            }
        ],
    };


    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    return (
        <Row>
            <Col lg="3">
                <Card className="sticky-top py-4" style={{ "top": "var(--falcon-top-nav-height)" }}>
                    <ul className="scrollspy" id="account" role="tablist">
                        <li className={`${activeSection === 0 ? 'active' : ''}`}>
                            <a href="#info" className="d-flex align-items-center py-3">
                                <span className="far fa-building"></span>&nbsp;Informações
                            </a>
                        </li>
                        <li className={`${activeSection === 1 ? 'active' : ''}`}>
                            <a href="#endereco" className="d-flex align-items-center py-3">
                                <span className="fas fa-map-marker-alt"></span>&nbsp;Endereço
                            </a>
                        </li>
                        <li className={`${activeSection === 2 ? 'active' : ''}`}>
                            <a href="#responsavel" className="d-flex align-items-center py-3">
                                <span className="far fa-user"></span>&nbsp;Responsável
                            </a>
                        </li>
                        <li className={`${activeSection === 3 ? 'active' : ''}`}>
                            <a href="#financeiro" className="d-flex align-items-center py-3">
                                <span className="far fa-money-bill-alt"></span>&nbsp;Financeiro
                            </a>
                        </li>
                    </ul>
                </Card>
            </Col>
            <Col lg="9">
                <div data-spy="scroll" data-bs-target="#account" data-bs-smooth-scroll="true" data-bs-root-margin="0px 0px -40%" className="--bg-body-tertiary" tabIndex="0">
                    <FormPadrao {...formEdit} />
                </div>
            </Col>
        </Row >
    );
}

export default Cadastro;