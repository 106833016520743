import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import useLoadData from "../../../../data/swr/useLoadData";
import RequestLoading from "../../../utils/request/RequestLoading";
import RequestError from "../../../utils/request/RequestError";
import { v4 as hash } from 'uuid';


const VerticalNav = props => {
    const { data, error, isLoading } = useLoadData('/navigation');
    if (isLoading) return <RequestLoading />;
    if (error) return <RequestError error={error} />;

    return (
        <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
            {data?.navigation?.map(m => {
                var unique = hash();
                return (
                    <li className="nav-item" key={unique}>
                        <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                            <div className="col-auto navbar-vertical-label">{m?.name}</div>
                            <div className="col ps-0"><hr className="mb-0 navbar-vertical-divider" /></div>
                        </div>
                        {menu_tree(m?.menus)}
                    </li>
                );
            })}
        </ul>
    )
}


export default VerticalNav;


function menu_tree(m) {
    const mp = m?.map((menu) => {
        const idUnique = menu?.name?.replace(/[ ]/g, '-').toLowerCase();
        const ul = (
            <React.Fragment key={menu?.id}>
                {
                    menu?.menus?.length ? (
                        <>
                            <Link to={`#${idUnique}`} className={`nav-link dropdown-indicator`} data-bs-toggle="collapse" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    {menu?.icon ? (
                                        <span className={`nav-link-icon`}>
                                            <span className={menu?.icon}></span>
                                        </span>
                                    ) : ""}
                                    <span className="nav-link-text ps-1">{menu?.name}</span>
                                </div>
                            </Link>
                            <ul className="nav collapse" id={idUnique} >
                                <li className="nav-item" >
                                    {menu_tree(menu.menus)}
                                </li>
                            </ul>
                        </>
                    ) : (
                        <Link to={menu?.path} className={`nav-link`}>
                            <div className="d-flex align-items-center">
                                {menu?.icon ? (
                                    <span className={`nav-link-icon`}>
                                        <span className={menu?.icon}></span>
                                    </span>
                                ) : ""}
                                <span className="nav-link-text ps-1">{menu?.name}</span>
                            </div>
                        </Link>
                    )
                }
            </React.Fragment>
        );

        return ul;
    });
    return mp;
}