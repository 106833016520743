import React, { useState } from "react";
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Card, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ModalFornecedor from "./ModalFornecedor";

const LayoutFornecedores = props => {

    const params = useParams();
    const navegate = useNavigate();
    const [paramsRequest, setParamsRequest] = useState({});
    const { register, handleSubmit } = useForm({ defaultValues: { type: 'PF' } });

    function onSubmit(formData) {
        const keys = Object.keys(formData);
        const query = [];
        keys.map(name => {
            if (formData[name]) query.push(`${name}=${formData[name]}`);
            return true;
        });
        setParamsRequest({ ...paramsRequest, query: `?${query.join('&')}` });
        return navegate(`/m3001/${params.md}/gestao-contas/fornecedor`);
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between">
                        <Col lg="auto">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="d-flex align-items-center">
                                    <div className="input-group input-search-width">
                                        <input className="form-control form-control-sm shadow-none search" type="search" placeholder="Busque por nome" {...register("search")} />
                                        <button type="submit" className="btn btn-sm btn-outline-secondary border-300 hover-border-secondary">
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                                    <div className="form-check form-check-inline mb-0 ms-3">
                                        <input className="form-check-input" id="type-pj" type="radio" value="PJ" {...register("type")} />
                                        <label className="form-check-label mb-0" htmlFor="type-pj">Pessoa Jurídica</label>
                                    </div>
                                    <div className="form-check form-check-inline mb-0">
                                        <input className="form-check-input" id="type-pf" type="radio" value="PF" {...register("type")} />
                                        <label className="form-check-label mb-0" htmlFor="type-pf">Pessoa Física</label>
                                    </div>
                                </div>
                            </form>
                        </Col>
                        <Col lg="auto">
                            <ModalFornecedor cmodule={params.md} mod={'m3001'} />
                            <Button className="btn-falcon-default btn-sm me-0">
                                <i className="fas fa-ellipsis-h fs--2"></i>
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <div className="layout-container-fornecedores">
                <Card className="mb-3 p-0">
                    <Card.Body className="p-0">
                        <Outlet context={{ ...paramsRequest }} />
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default LayoutFornecedores;