import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLoadData from '../../../../../data/swr/useLoadData';
import RequestError from '../../../../utils/request/RequestError';
import RequestLoading from '../../../../utils/request/RequestLoading';
import ChatTime from '../../../../utils/chat/ChatTime';
import ChatMessage from '../../../../utils/chat/ChatMessage';
import socket from '../../../../../data/socket/index.js';
import { mutate } from 'swr';

const BodyChatMessage = (props) => {
  const params = useParams();
  const baseUrl = `/m3002/${params.md}/chat/${params.hash ? params.hash : 'new'}`;
  const { data, error, isLoading } = useLoadData(baseUrl);
  const [messages, setMessages] = useState([]);

  const messageEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  // Função para manter o scroll no final
  const scrollToBottom = () => {
    setTimeout(() => {
      if (messageEndRef && messageEndRef.current) {
        messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  // Função para verificar se o usuário está no final do chat
  const isUserAtBottom = () => {
    if (!chatContainerRef.current) return false;
    const { scrollHeight, scrollTop, clientHeight } = chatContainerRef.current;
    return scrollHeight - scrollTop === clientHeight;
  };

  useEffect(() => {
    mutate(baseUrl);

    const handleNewMessage = (message) => {
      if (message && message.contact_hash === params.hash) {
        const isAtBottom = isUserAtBottom(); // Verifica se o usuário está no final do chat
        setMessages((prevMessages) => {
          const messageIndex = prevMessages?.findIndex((m) => m.hash === message.hash);
          if (messageIndex >= 0) {
            const updatedMessages = [...prevMessages];
            updatedMessages[messageIndex] = { ...prevMessages[messageIndex], ...message };
            return updatedMessages;
          } else {
            return [...prevMessages, message];
          }
        });

        // Se o usuário estiver no final, rola automaticamente para o final
        if (isAtBottom) {
          scrollToBottom();
        }
      }
    };

    socket.on('wpp_message', handleNewMessage);
    socket.on('wpp_message_statuses', handleNewMessage);
    return () => {
      socket.off('wpp_message', handleNewMessage);
      socket.off('wpp_message_statuses', handleNewMessage);
    };
  }, [params.hash, baseUrl]);

  useEffect(() => {
    if (data) {
      setMessages(data);
    }
  }, [data]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  if (error) return <RequestError b="0" error={error} />;
  if (isLoading) return <RequestLoading />;
  let lastDate = null;
  return (
    <React.Fragment>
      <div key={params.hash} ref={chatContainerRef} className="h-100 w-100">
        {messages?.map((item) => {
          const messageDate = new Date(item.created_at).toISOString().split('T')[0];
          let showDate = false;
          if (lastDate !== messageDate) {
            lastDate = messageDate;
            showDate = true;
          }
          return (
            <React.Fragment key={item.hash + item.status}>
              {showDate && <ChatTime date={item.created_at} />}
              <ChatMessage message={item} />
            </React.Fragment>
          );
        })}
        <div ref={messageEndRef} />
      </div>
    </React.Fragment>
  );
};

export default BodyChatMessage;
