import React from 'react';
import Moment from 'react-moment';

const ChatTime = ({ date }) => {
  date = date || new Date();
  return (
    <div className="text-center fs--2 text-500 sticky-top card shadow-none">
      <Moment format="LL">{date}</Moment>
    </div>
  );
};

export default ChatTime;
