import React from "react";
import { Card, Col, Row } from 'react-bootstrap';
import useLoadData from "../../../../data/swr/useLoadData";
import RequestError from "../../../utils/request/RequestError";
import RequestLoading from "../../../utils/request/RequestLoading";
import ModalNewSetor from "./ModalNewSetor";
import AvatarName from "../../../adm/utils/AvatarName";
import ModalEditSetor from "./ModalEditSetor";

const Setores = props => {

    const { data, error, isLoading } = useLoadData('/empresa/setores');
    const sectors = data?.sectors;

    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    if (!sectors?.length) return (
        <Row className="justify-content-center">
            <Col lg="6">
                <Card>
                    <Card.Body className=" p-4 p-sm-5">
                        <div className="text-center">
                            <span className="fw-black lh-1 text-300 fs-error">
                                <span className="fas fa-chart-pie"></span>
                            </span>
                            <p className="mt-3">Não há setores cadastrados no momento. <br /> Por favor, cadastre um novo setor para continuar.</p>
                            <ModalNewSetor />
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
    return (
        <>
            <Row className="mb-3 justify-content-between">
                <Col md="auto">
                    <h4 className="m-0 d-none">Setores<span className="fw-normal ms-1">({sectors?.length})</span></h4>
                </Col>
                <Col md="auto">
                    <ModalNewSetor button="btn btn-falcon-primary" />
                </Col>
            </Row>
            <Row>
                {sectors?.map(s => {
                    return (
                        <Col key={s?.id} lg="4" md="6" className="mb-3">
                            <Card className="h-100 hover-actions-trigger">
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-2 lh-sm flex-1 me-5">{s?.name}</h5>
                                        <div className="hover-actions top-0 end-0 mt-4 me-4">
                                            <ModalEditSetor id={s?.id} />
                                        </div>
                                    </div>
                                    <span className={`badge badge-subtle-${s?.active ? 'success' : 'danger'}`}>{s?.active ? 'Ativo' : 'Inativo'}</span>
                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        {!s?.users?.length ? <small className=""><i>Sem Usuário vinculado</i></small> : (
                                            <ul className="nav avatar-group mb-0 d-none d-md-flex">
                                                {
                                                    s?.users?.map((u, i) => {
                                                        return (
                                                            <li className="nav-item" key={i}>
                                                                <AvatarName {...u} />
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        )}
                                        <div className="">
                                            <span className="fas fa-users"></span> {s?.users?.length}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </>
    );
}

export default Setores;