import React from "react";
import { Outlet } from "react-router-dom";
import ChatWppChatContactList from "./ContactList";


const LayoutChatWppChat = props => {
    return (
        <>
            <div className="card card-chat overflow-hidden">
                <div className="card-body d-flex p-0 h-100">
                    <div className="chat-sidebar">
                        <ChatWppChatContactList />
                    </div>
                    <div className="tab-content card-chat-content">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}

export default LayoutChatWppChat;