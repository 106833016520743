import React, { useEffect, useState } from "react";
import RequestError from "../../../../utils/request/RequestError";
import RequestLoading from "../../../../utils/request/RequestLoading";
import useLoadData from "../../../../../data/swr/useLoadData";
import { useParams } from "react-router-dom";
import Table from "../../../../utils/table";
import { useOutletContext } from 'react-router-dom';
import { BadgeActive, BadgeInactive } from "../../../../adm/utils/Badge";
import { Link } from "react-router-dom";
import Avatar from "../../../../utils/avatar";

 
const TabelaFornecedores = props => {


    const params = useParams();
    const context = useOutletContext();
    const [fornecedores, setFornecedores] = useState([]);
    const { data, error, isLoading } = useLoadData(`/m3001/${params.md}/fornecedores${context?.query || ''}`);

    const columns = [
        { label: "Nome", column: "name" },
        { label: "E-mail", column: "email" },
        { label: "Telefone", column: "phone" },
        { label: "Endereço", column: "address" },
        { label: "Data/Hora", column: "created_at" },
    ];

    useEffect(() => {
        if (data?.fornecedores) setFornecedores([...data.fornecedores]);
    }, [data]);


    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Tr>
                        {columns.map(n => <Table.Th column={n.column} key={n.column}>{n.label}</Table.Th>)}
                    </Table.Tr>
                </Table.Header>
                <Table.Body>
                    {
                        fornecedores.map((forn, i) => {
                            forn.id = forn.id ? forn.id : i;
                            var value = '';
                            return (
                                <Table.Tr key={forn.id}>
                                    {columns.map((n, i) => {

                                        switch (n.column) {
                                            case 'active':
                                                value = forn[n.column] ? <BadgeActive /> : <BadgeInactive />;
                                                break;
                                            case 'name':
                                                value = (
                                                    <Link className="text-900" to={`/m3001/${params.md}/gestao-contas/fornecedor/${forn.id}`}>
                                                        <Avatar label={forn[n.column]}>
                                                            <Avatar.Name active={forn['active']} name={forn[n.column]} />
                                                        </Avatar>
                                                    </Link>
                                                )
                                                break;
                                            default:
                                                value = forn[n.column];
                                                break;
                                        }
                                        return <Table.Td key={n.column} >{value}</Table.Td>
                                    })}
                                </Table.Tr>
                            );
                        })
                    }
                </Table.Body>
            </Table>
            {
                !fornecedores.length && (
                    <div className="text-center p-4">
                        <small>Nada encontrado! Ajuste o filtro e tente novamente!</small>
                    </div>
                )
            }
        </>
    );
}



export default TabelaFornecedores;