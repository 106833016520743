import React from 'react';
import Moment from 'react-moment';
import LayoutMessage from '../../app/modulos/3002-chatwpp/chat/layout-type-message';

const ChatMessage = ({ message }) => {
  const isSent = message.direction === 'sent';
  const isReceived = message.direction === 'received';
  const time = message.created_at || new Date();
  const status = message.status;
  // const errors = props.errors;

  return (
    <div className={`d-flex px-3 py-2 ${isSent ? '' : ''} ${isReceived ? 'message-received' : 'message-sent'}`}>
      <div className={`flex-1 d-flex ${isSent ? 'justify-content-end' : ''}`}>
        <div className="w-100 w-xxl-75">
          <div className={`hover-actions-trigger d-flex ${isSent ? 'flex-end-center' : 'align-items-center'}`}>
            <div data-hash={message.hash} className={`${isSent ? 'bg-primary text-white' : 'bg-200'} p-2 rounded-2 chat-message position-relative`}>
              <LayoutMessage message={message} />
            </div>
          </div>
          <div className={`text-400 fs--2 ${isSent ? 'text-end' : 'd-none--'}`}>
            <Moment format="HH:mm">{time}</Moment>
            {isSent && <ChatIconStatus status={status} message={message} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatIconStatus = (props) => {
  const status = props.status || 'sent';
  const message = props.message || {};
  const erros = message.errors || {};
  const title = erros.message ? 'Falhada - ' + erros.message : 'Falhada';
  if (status === 'sent') return <span title="Enviada" className="fas fa-check ms-2"></span>;
  if (status === 'received') return <span title="Recebida" className="fas fa-check-double ms-2"></span>;
  if (status === 'delivered') return <span title="Entregue" className="fas fa-check-double ms-2"></span>;
  if (status === 'read') return <span title="Lida" className="fas fa-check-double ms-2 text-success"></span>;
  if (status === 'failed') return <span title={title} className="fas fa-times-circle ms-2"></span>;
  if (status === 'pending') return <span title="Pendente" className="far fa-clock ms-2"></span>;
  if (status === 'queued') return <span title="Fila para Envio" className="fas fa-list-ol ms-2"></span>;
  if (status === 'rejected') return <span title="Rejeitada" className="fas fa-ban ms-2"></span>;
};

export default ChatMessage;
