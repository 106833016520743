import React from "react";
import { Outlet } from 'react-router-dom';


const LayoutContasPagar = props => {
    return (
        <>
            <div>Contas a Pagar</div>
            <div className="layout-container-contas-pagar">
                <Outlet />
            </div>
        </>
    );
}

export default LayoutContasPagar;