import React, { useState, useRef, useEffect } from 'react';
import api from '../../../../../../data/axios/api';
import socket from '../../../../../../data/socket';
import { useParams } from 'react-router-dom';

const AudioRecorder = (props) => {
  const contact = props.contact;
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null); // Para armazenar o URL do áudio gravado
  const audioChunksRef = useRef([]); // Usar ref para armazenar os pedaços de áudio
  const mediaRecorderRef = useRef(null); // Armazenar a instância do MediaRecorder
  const audioStreamRef = useRef(null); // Armazenar a referência ao stream do microfone

  const params = useParams();
  const [contactHash, setContactHash] = useState(params.hash);
  const [modId, setModId] = useState(params.md);

  useEffect(() => {
    setContactHash(params.hash);
    setModId(params.md);
  }, [params]);

  const handleStartRecording = async () => {
    try {
      handleDeleteAudio();

      // Solicita permissão e acesso ao microfone com melhores configurações de áudio
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStreamRef.current = stream;

      // Definir opções de codec e taxa de bits para uma melhor qualidade
      const options = {
        mimeType: 'audio/webm;codecs=opus',
        audioBitsPerSecond: 128000, // Ajuste a taxa de bits para 128kbps
      };

      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorderRef.current = mediaRecorder;

      audioChunksRef.current = []; // Limpa os chunks de áudio antigos
      setIsRecording(true);
      mediaRecorder.start();

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          // Adiciona o chunk ao ref, sem depender do estado assíncrono
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        if (audioChunksRef.current.length > 0) {
          const audioBlob = new Blob(audioChunksRef.current, { type: options.mimeType });
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);
        } else {
          console.error('Nenhum chunk de áudio foi gravado.');
        }

        // Parar o stream do microfone para liberar o recurso
        if (audioStreamRef.current) {
          audioStreamRef.current.getTracks().forEach((track) => track.stop());
          audioStreamRef.current = null; // Limpa o stream do microfone
        }
      };
    } catch (err) {
      console.error('Acesso ao microfone negado ou não disponível.', err);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      setIsRecording(false);
      mediaRecorderRef.current.stop(); // Para a gravação
    }
  };

  const handleDeleteAudio = () => {
    if (audioUrl) {
      URL.revokeObjectURL(audioUrl);
      setAudioUrl(null);
    }
    audioChunksRef.current = []; // Limpa os chunks de áudio
  };

  const handleSendAudio = async () => {
    try {
      if (!audioUrl) return;
      if (!audioChunksRef.current.length) return;

      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm; codecs=opus' });
      const formData = new FormData();
      formData.append('file', audioBlob, 'audio.webm');
      formData.append('folder', contact?.folder_id);

      api.defaults.headers['Content-Type'] = `multipart/form-data`;
      const { data: dataFile } = await api.post('/files', formData);

      const filePath = dataFile.path;

      const options = {
        contact_hash: contactHash,
        mod_id: modId,
      };

      const formBody = {
        type: 'audio',
        drive_file_id: dataFile.id,
        audio: {
          link: process.env.REACT_APP_BASEURL + filePath,
        },
      };
      socket.emit('send_message', formBody, options);
      handleDeleteAudio();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex  align-items-center">
      {audioUrl && (
        <>
          <button className="btn btn-link m-0 shadow-none fs-1" onClick={handleDeleteAudio}>
            <i className="fas fa-trash text-muted"></i>
          </button>
          <audio
            className="bg-light"
            style={{ width: '400px', height: '35px', border: 'none', borderRadius: '25px', outline: 'none' }}
            controls
            src={audioUrl}
          ></audio>
        </>
      )}
      {isRecording && (
        <button className="btn btn-link m-0 shadow-none text-danger fs-1" onClick={handleStopRecording} disabled={!isRecording}>
          <i className="fas fa-stop-circle"></i>
        </button>
      )}
      <button className="btn btn-link m-0 shadow-none fs-1" onClick={handleStartRecording} disabled={isRecording}>
        <i className="fas fa-microphone"></i>
      </button>
      {audioUrl && (
        <button className="btn btn-link m-0 shadow-none fs-1" onClick={handleSendAudio}>
          <i className="fas fa-play text-success"></i>
        </button>
      )}
    </div>
  );
};

export default AudioRecorder;
