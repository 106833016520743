import React from "react";
import useLoadData from "../../../../../../data/swr/useLoadData";
import { useParams } from "react-router-dom";
import RequestError from "../../../../../utils/request/RequestError";
import RequestLoading from "../../../../../utils/request/RequestLoading";
import { Badge, Table } from "react-bootstrap";
import { formatTelefone, onFormSubmit } from "../../../../../../scripts/utils";
import { Link } from "react-router-dom";
import DropdownCustom from "../../../../../utils/dropdown";
import { mutate } from "swr";
import IconCustom from "../../../../../utils/icon";


const ChatWppContactList = props => {
    const params = useParams();
    const baseUrl = `/m3002/${params.md}`;
    const { data, error, isLoading } = useLoadData(`${baseUrl}/contact`);


    const columns = [
        { title: 'Situação', key: 'active', formated: (v)=> v ? <Badge bg="success">Ativo</Badge> : <Badge bg="danger">Inativo</Badge> },
        { title: 'Nome', key: 'name' },
        { title: 'Telefone', key: 'phone_number', formated: formatTelefone },
        { title: 'E-mail', key: 'email' },
        { title: 'Cadastrado', key: 'created_at' },
        { title: 'Ações', key: 'action' },
    ];

    const putStatusContact = async (item) => {
        const active = item.active ? "false" : "true";
        await onFormSubmit({
            action: `${baseUrl}/contact/${item.hash}`,
            method: 'PUT',
            data: {...item, active},
            onSubmitSuccess: () => mutate(`/m3002/${params.md}/contact`)
        });
    }


    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    return (
        <>
            <Table responsive className="table table-sm table-striped fs--1 mb-0 overflow-hidden">
                <thead className="bg-200 text-900">
                    <tr>
                        {columns.map(columm=> (<th className="col" key={columm.key}>{columm.title}</th>))}
                    </tr>
                </thead>
                <tbody>
                    {data?.map(item=> (
                        <tr key={item.id}>
                            {columns.map(columm => {
                                if( item[columm.key]!==undefined ) return (
                                    <td className="text-nowrap py-2" key={item.id+"-"+columm.key}>
                                        <Link className="dropdown-item" to={{ pathname: `${baseUrl}/contact/${item.hash}` }}>
                                            { columm.formated ? columm.formated(item[columm.key]) : item[columm.key]}
                                        </Link>
                                    </td>
                                )
                                return '';
                            })}
                            <td>
                                <DropdownCustom>
                                    <DropdownCustom.Link to={{ pathname: `${baseUrl}/chat/${item.hash}` }}>
                                        <IconCustom icon="fab fa-weixin"/> Conversar
                                    </DropdownCustom.Link>
                                    <DropdownCustom.Link to={{ pathname: `${baseUrl}/contact/${item.hash}` }}>
                                        <IconCustom icon="fas fa-user-edit"/> Editar
                                    </DropdownCustom.Link>
                                    <DropdownCustom.Button onClick={()=>putStatusContact(item)}>
                                        <IconCustom icon="fas fa-star"/>
                                        {item.active ? " Inativar" : " Ativar"}
                                    </DropdownCustom.Button>
                                </DropdownCustom>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}

export default ChatWppContactList;