import React from "react";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Outlet } from 'react-router-dom';


const LayoutChatWppContact = props => {
    const params = useParams();
    const baseUrl = "/m3002/"+params.md;
    return (
        <>
        <Row>
            <Col lg="12 mb-4">
                <Card>
                    <Card.Body>
                        <Row className="justify-content-between align-items-center">
                            <Col lg="auto">
                                <h4 className="text-900">Contatos Cadastrados</h4>
                                <h6 className="text-muted mb-0">Gerencie seu contatos.</h6>
                            </Col>
                            <Col lg="auto">
                                <Link to={`/m3002/${params.md}/contact/new`} className="btn btn-outline-primary btn-sm">
                                    Novo
                                </Link>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg="2">
                <Nav variant="pills" defaultActiveKey={1} className="flex-column">
                    <Nav.Item>
                        <Nav.Link eventKey={1} as={"span"}>
                            <Link to={baseUrl+"/contact/list"}>
                                Contatos 
                            </Link>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={2} as={"span"}>
                            <Link to={baseUrl+"/contact/group"}>Grupos de Contatos</Link>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Col>
            <Col>
                <Card>
                    <Card.Body>
                        <Outlet/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </>
    );
}

export default LayoutChatWppContact;