import React from 'react';
import { useParams } from 'react-router-dom';

const BodyChatMain = (props) => {
  const params = useParams();

  return <div className="h-100 w-100">Inicial</div>;
};

export default BodyChatMain;
