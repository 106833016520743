import React from "react";
import { Card } from "react-bootstrap";
import useLoadData from "../../../data/swr/useLoadData";
import RequestError from "../../utils/request/RequestError";
import RequestLoading from "../../utils/request/RequestLoading";
import AvatarName from "../utils/AvatarName";
import { formatCNPJ, formatTelefone } from "../../../scripts/utils";
import ModalClientAdd from "./ModalClientAdd";
import { Link } from "react-router-dom";
import AcessSchemaClient from "./AccessSchemaClient";


const Clients = props => {

    const { data, error, isLoading } = useLoadData('/adm/clients');
    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);

    return (
        <Card>
            <Card.Header>
                <div className="row flex-between-center">
                    <div className="col-4 col-sm-auto d-flex align-items-center pe-0">
                        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Clientes</h5>
                    </div>
                    <div className="col-8 col-sm-auto text-end-- ps-2">
                        <ModalClientAdd />
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="p-0">
                <table className="table table-sm table-striped fs--1 mb-0 overflow-hidden">
                    <thead className="bg-200 text-900">
                        <tr>
                            <th className="col">Empresa</th>
                            <th className="col">Documento</th>
                            <th className="col">Telefone</th>
                            <th className="col">E-mail</th>
                            <th className="col">Cadastro</th>
                            <th className="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.clients?.map(e => {

                                return (
                                    <tr key={e?.id}>
                                        <td className="text-nowrap py-2">
                                            <Link className="" to={{ pathname: `/adm/clientes/${e?.id}` }}>
                                                <div className="d-flex align-items-center">
                                                    <AvatarName {...e} />
                                                    <h6 className="ms-2 mb-0">
                                                        <span className="text-900">
                                                            {e?.fantasy}
                                                        </span>
                                                    </h6>
                                                </div>
                                            </Link>
                                        </td>
                                        <td className="text-nowrap py-2">
                                            {formatCNPJ(e?.document_number)}
                                        </td>
                                        <td className="text-nowrap py-2">
                                            {!!e?.phone && <div>{formatTelefone(e?.phone)}</div>}
                                            {!!e?.finances_phone && <div>{formatTelefone(e?.finances_phone)}</div>}
                                        </td>
                                        <td className="text-nowrap py-2">
                                            {!!e?.email && <div>{e?.email}</div>}
                                            {!!e?.finances_email && <div>{e?.finances_email}</div>}
                                        </td>
                                        <td className="text-nowrap py-2">
                                            {e?.created_at}
                                        </td>
                                        <td className="text-nowrap py-2">
                                            <div className="dropdown font-sans-serif position-static">
                                                <button className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
                                                    <span className="fas fa-ellipsis-h fs--1"></span>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-end border py-0">
                                                    <div className="py-2">
                                                        <Link className="dropdown-item" to={{ pathname: `/adm/clientes/${e?.id}` }}>
                                                            Editar
                                                        </Link>
                                                        <AcessSchemaClient className="dropdown-item" label={"Acessar Sistema"} schema={e?.id} />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </Card.Body>
        </Card>
    );
}


export default Clients;