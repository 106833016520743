import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Nav, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useLoadData from '../../../../../data/swr/useLoadData';
import RequestError from '../../../../utils/request/RequestError';
import RequestLoading from '../../../../utils/request/RequestLoading';
import Avatar from '../../../../utils/avatar';
import IconCustom from '../../../../utils/icon';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import socket from '../../../../../data/socket';
import DropdownCustom from '../../../../utils/dropdown';

const ChatWppChatContactList = (props) => {
  const params = useParams();
  const baseUrl = '/m3002/' + params.md;
  const { data, error, isLoading } = useLoadData(`${baseUrl}/chat/contact`);

  const [contacts, setContacts] = useState([]);
  const [contactsFilted, setContactsFilted] = useState([]);
  const [key, setKey] = useState('');

  const handleChange = (event) => {
    setKey(event.target.value);
  };

  useEffect(() => {
    const handleNewMessage = (contact) => {
      if (contact) {
        setContacts((prevContacts) => {
          const messageIndex = prevContacts?.findIndex((m) => m.hash === contact.hash);
          var updatedContacts = [];
          if (messageIndex >= 0) {
            updatedContacts = [...prevContacts];
            updatedContacts[messageIndex] = { ...prevContacts[messageIndex], ...contact };
          } else {
            updatedContacts = [...prevContacts, contact];
          }
          return updatedContacts.sort((a, b) => new Date(b.ts_last_message) - new Date(a.ts_last_message));
        });
      }
    };
    socket.on('wpp_contact', handleNewMessage);
    return () => {
      socket.off('wpp_contact', handleNewMessage);
    };
  }, [params.hash]);

  useEffect(() => {
    if (data) setContacts(data);
  }, [data]);

  useEffect(() => {
    if (!key) return setContactsFilted(contacts);
    const contactsLoading = contacts.filter((c) => c.name?.indexOf(key) > -1 || c.contact_name?.indexOf(key) > -1);
    setContactsFilted(contactsLoading);
  }, [key, contacts]);

  if (error) return <RequestError error={error} />;
  if (isLoading) return <RequestLoading />;
  return (
    <>
      <div className="" style={{ borderRight: '1px solid var(--falcon-border-color)' }}>
        <div className="p-3">
          <Row className="align-items-center justify-content-between">
            <Col>
              <h4 className="m-0">Conversas</h4>
            </Col>
            <Col lg="auto">
              <DropdownCustom icon={'fas fa-ellipsis-v'}>
                <DropdownCustom.Item>Arquivadas</DropdownCustom.Item>
              </DropdownCustom>
            </Col>
          </Row>
        </div>
        <div className="px-3 pb-3">
          <InputGroup>
            <InputGroup.Text>
              <i className="fas fa-search"></i>
            </InputGroup.Text>
            <Form.Control placeholder="Pesquisar" onChange={handleChange} />
          </InputGroup>
        </div>
      </div>
      <div className="contacts-list scrollbar-overlay">
        <div className="simplebar-wrapper" style={{ margin: 0 }}>
          <div className="simplebar-mask">
            <div className="simplebar-offset">
              <div className="simplebar-content-wrapper">
                <div className="simplebar-content">
                  <Nav variant="tabs" className="flex-column" defaultActiveKey={params.hash}>
                    {contactsFilted?.map((item) => {
                      return (
                        <Nav.Link
                          as="div"
                          key={item.hash}
                          eventKey={item.hash}
                          bsPrefix="--"
                          className={`nav-item chat-contact hover-actions-trigger --unread-message read-message`}
                        >
                          <Link to={{ pathname: `${baseUrl}/chat/${item.hash}` }}>
                            <div className="d-flex p-3">
                              <Avatar>
                                <Avatar.Name name={item.name} active={true} />
                              </Avatar>
                              <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div className="d-flex justify-content-between">
                                  <h6 className="mb-0 chat-contact-title">{item.name}</h6>
                                  {item.ts_last_message && (
                                    <span className="message-time fs--2">
                                      <Moment fromNowDuring format="DD/MM/YY [às] HH:mm">
                                        {item.ts_last_message}
                                      </Moment>
                                    </span>
                                  )}
                                </div>
                                <div className="min-w-0">
                                  <div className="chat-contact-content pe-3">
                                    {item.last_message ? item.last_message : 'Envie a primeira mensagem!'}
                                  </div>
                                  <div className="position-absolute bottom-0 end-0 hover-hide">
                                    {item.last_contacted_at && <IconCustom icon="fas fa-check text-success" />}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </Nav.Link>
                      );
                    })}
                  </Nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contacts-search-wrapper">Form</div>
    </>
  );
};

export default ChatWppChatContactList;
