import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Dropdown from 'react-bootstrap/Dropdown';
import { Card, Col, Row } from 'react-bootstrap';
import api from '../../../data/axios/api';
// import iconFolder from '../icon/image-file-2.png';
import ShowError from '../request/ShowError';


const InputDrive = ({ title, folder, fileList, setValue }) => {

    const [files, setFiles] = useState([]);

    useEffect(() => {

        if (fileList?.length) setFiles(fileList);

    }, [fileList]);


    // ATUALIZA O CARREGAMENTO DO ARQUIVO
    const updateFileProgress = async (fileToUpdate, progress = 0, error = false) => {

        const loaded = Number(progress) === 100 ? true : false;

        setFiles(files =>
            files.map(file => file.path === fileToUpdate.path ? Object.assign(file, { progress, loaded, error }) : file)
        );
    };

    // REMOVE O ARQUIVO DA LISTA
    const handleRemove = path => {
        setFiles(files.filter(file => file.path !== path));
    };

    // START USEDROP
    const { getRootProps, getInputProps, isDragActive, isDragReject, open } = useDropzone({
        // accept: 'image/*',
        noClick: true,
        onDrop: async acceptedFiles => {

            setFiles([
                ...files,
                ...acceptedFiles.map(file =>
                    Object.assign(file, {
                        url: URL.createObjectURL(file),
                        progress: 0,
                        loaded: false,
                        error: false
                    })
                )
            ]);

            await Promise.all(acceptedFiles.map(async file => {

                try {

                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('fileOriginalPath', file.path);
                    if (folder) formData.append('folder', folder);

                    api.defaults.headers['Content-Type'] = `multipart/form-data`;
                    await api.post('/files', formData, {
                        onUploadProgress: progressEvent => {
                            const per = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            updateFileProgress(file, per);
                        }
                    });

                    updateFileProgress(file, 100);

                } catch (error) {
                    updateFileProgress(file, '', true);
                    return ShowError(error);
                }
            }));
        }
    });


    return (
        <div {...getRootProps({ className: `dropzone dropzone-area- py-6- ` })}>
            <Card className="mb-3">
                <Card.Header>
                    <Row>
                        <Col>
                            <h5 className='m-0'>{title ? title : 'Drive'}</h5>
                        </Col>
                        <Col lg="auto">
                            <button type='button' className='btn btn-falcon-primary' onClick={open}>
                                <i className='fas fa-cloud-upload-alt'></i> Novo
                            </button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="bg-light">
                    <input
                        name="files"
                        {...(setValue && { ...setValue('files', files) })}
                        {...getInputProps()}
                    />
                    <div className='w-100'>
                        {!files.length ? (
                            <div className={`dropzone-area py-6 ${isDragReject ? 'border-danger' : isDragActive ? 'border-success' : ''}`}>
                                <Row className="justify-content-center">
                                    <Col lg="auto">
                                        <i className='fas fa-cloud-upload-alt fs-3'></i>
                                    </Col>
                                    <Col lg="auto">
                                        <p className="fs-0 mb-0 text-700">
                                            {isDragReject ? 'Arquivo não suportado' : ''}
                                            {isDragActive ? 'Arraste arquivos aqui...' : ''}
                                            {!isDragActive && !isDragReject ? 'Solte os arquivos aqui' : ''}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            files.map((file, index) => {

                                const opacity = !file.loaded && !file.error && file.progress ? '0.2' : '1';
                                return (
                                    <Row
                                        className="py-3 border-bottom btn-reveal-trigger align-items-center"
                                        key={file.path}
                                    >
                                        <Col lg="auto">
                                            {
                                                file?.type?.indexOf('image/') > -1 ? (
                                                    <img style={{ "opacity": opacity }} className="rounded" width={40} height={40} src={file.url} alt={file.path} />
                                                ) : (
                                                    <>{getIcon(file?.type)}</>
                                                    // <img style={{ "opacity": opacity }} className="rounded" width={40} height={40} src={iconFolder} alt={file.path} />
                                                )
                                            }
                                        </Col>
                                        <Col>
                                            <Row className="justify-content-between align-items-center">
                                                <Col>
                                                    <div>
                                                        <h6 className='mb-0'>
                                                            {file.name}
                                                            {!file.loaded && !file.error && file.progress ? (<span> {file.progress}%</span>) : ''}
                                                            {file.error ? (<span> ERROR </span>) : ''}
                                                        </h6>
                                                        <p className="mb-0 fs--1 text-400 line-height-1">
                                                            <strong>{getSize(file.size)}</strong>
                                                            <strong> {file.type}</strong>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col lg="auto">
                                                    <Dropdown>
                                                        <Dropdown.Toggle className='btn-link text-600 btn-sm dropdown-toggle btn-reveal dropdown-caret-none'>
                                                            <span className="fas fa-ellipsis-h fs--1"></span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className=' border py-2'>
                                                            <Dropdown.Item onClick={() => handleRemove(file.path)} >
                                                                Remove
                                                            </Dropdown.Item>
                                                            <Dropdown.Item href={file.url} target='_blank'>
                                                                Ver
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                        )}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default InputDrive;



const getSize = size => {
    size = Number(size);
    if (size < 1024) {
        return (
            <>
                <strong>{size.toFixed(2)}</strong> Byte
            </>
        );
    } else if (size < 1024 * 1024) {
        return (
            <>
                <strong>{(size / 1024).toFixed(2)}</strong> KB
            </>
        );
    } else {
        return (
            <>
                <strong>{(size / (1024 * 1024)).toFixed(2)}</strong> MB
            </>
        );
    }
};

const getIcon = type => {
    var icon = '';
    switch (true) {
        case type.indexOf('pdf') > -1: icon = 'fas fa-file-pdf'; break;
        case type.indexOf('csv') > -1: icon = 'fas fa-file-csv'; break;
        case type.indexOf('sheet') > -1: icon = 'fas fa-file-excel'; break;
        case type.indexOf('excel') > -1: icon = 'fas fa-file-excel'; break;
        case type.indexOf('word') > -1: icon = 'fas fa-file-word'; break;
        case type.indexOf('text') > -1: icon = 'fas fa-file-alt'; break;
        case type.indexOf('zip') > -1: icon = 'fas fa-file-archive'; break;
        case type.indexOf('rar') > -1: icon = 'fas fa-file-archive'; break;
        case type.indexOf('audio') > -1: icon = 'fas fa-file-audio'; break;
        case type.indexOf('video') > -1: icon = 'fas fa-file-video'; break;
        case type.indexOf('power') > -1: icon = 'fas fa-file-powerpoint'; break;

        case type.indexOf('application') > -1: icon = 'fas fa-file-code'; break;
        default: icon = 'fas fa-file'; break;
    }

    return <span className={`${icon} fs-6`}></span>
}