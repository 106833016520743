import React from "react";
import { Link } from "react-router-dom";
import img from "../../components/utils/icon/bg-shape.png";
import img1 from "../../components/utils/icon/shape-1.png";
import SetPasswordForm from "./SetPasswordForm";

const LayoutDefinedPassHash = props => {

    return (
        <div className="row min-vh-100 flex-center g-0">
            <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                <img src={img} alt="" className="bg-auth-circle-shape" />
                <img src={img1} alt="" className="bg-auth-circle-shape-2" />
                <div className="card overflow-hidden z-1">
                    <div className="card-body p-0">
                        <div className="row g-0 h-100">
                            <div className="col-md-5 text-center bg-card-gradient">
                                <div className="position-relative p-4 pt-md-5 pb-md-3" data-bs-theme="light">
                                    <div className="z-1 position-relative">
                                        <Link className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder">
                                            Controle SGA
                                        </Link>
                                        <p className="opacity-75 text-white">
                                            Software projetado para auxiliar empresas a gerenciar clientes que assinam serviços ou produtos recorrentes, automatizando tarefas como cobrança, renovação e cancelamento de assinaturas.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-3 mb-4 mt-md-4 mb-md-5" data-bs-theme="light">
                                    <p className="text-white">
                                        Faça um teste grátis?&nbsp;
                                        <Link className="text-decoration-underline link-light">
                                            Cadastre-se
                                        </Link>
                                    </p>
                                    <p className="mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold text-white opacity-75">
                                        Veja os&nbsp;
                                        <Link className="text-decoration-underline text-white">
                                            Termos
                                        </Link>
                                        &nbsp;e&nbsp;
                                        <Link className="text-decoration-underline text-white">
                                            Condições
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-7 d-flex flex-center">
                                <div className="p-4 p-md-5 flex-grow-1">
                                    <div className="row flex-between-center">
                                        <div className="col-auto">
                                            <h3>Informações de Acesso</h3>
                                        </div>
                                    </div>
                                    <SetPasswordForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default LayoutDefinedPassHash;