import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { mutate } from "swr";
import useLoadData from "../../../../../../../data/swr/useLoadData";
import RequestError from "../../../../../../utils/request/RequestError";
import RequestLoading from "../../../../../../utils/request/RequestLoading";
import FormPadrao from "../../../../../../utils/form";


const ChatWppContactDetails = props => {
    const params = useParams();
    const navegate = useNavigate();
    const { data, error, isLoading } = useLoadData(`/m3002/${params.md}/contact/${params.hash}`);


    function onSubmitSuccess() {
        if(params.hash === "new"){
            navegate(`/m3002/${params.md}/contact`);
        } else {
            mutate(`/m3002/${params.md}/contact/${params.hash}`);
        }
    }

    const optionsCountryCode = [
        {'value':'55','label':'+55 - Brasil'},
        {'value':'1','label':'+1 - Estados Unidos / Canadá'},
        {'value':'44','label':'+44 - Reino Unido'},
        {'value':'61','label':'+61 - Austrália'},
        {'value':'33','label':'+33 - França'},
        {'value':'49','label':'+49 - Alemanha'},
        {'value':'81','label':'+81 - Japão'},
        {'value':'86','label':'+86 - China'},
        {'value':'91','label':'+91 - Índia'},
        {'value':'34','label':'+34 - Espanha'},
        {'value':'39','label':'+39 - Itália'},
        {'value':'7','label':'+7 - Rússia'},
        {'value':'351','label':'+351 - Portugal'},
        {'value':'32','label':'+32 - Bélgica'},
        {'value':'31','label':'+31 - Países Baixos'},
        {'value':'41','label':'+41 - Suíça'},
        {'value':'46','label':'+46 - Suécia'},
        {'value':'52','label':'+52 - México'},
        {'value':'54','label':'+54 - Argentina'},
        {'value':'56','label':'+56 - Chile'},
        {'value':'57','label':'+57 - Colômbia'},
        {'value':'20','label':'+20 - Egito'},
        {'value':'27','label':'+27 - África do Sul'},
        {'value':'62','label':'+62 - Indonésia'},
        {'value':'63','label':'+63 - Filipinas'},
        {'value':'64','label':'+64 - Nova Zelândia'},
        {'value':'65','label':'+65 - Singapura'},
        {'value':'60','label':'+60 - Malásia'},
        {'value':'972','label':'+972 - Israel'},
        {'value':'90','label':'+90 - Turquia'},
        {'value':'966','label':'+966 - Arábia Saudita'},
        {'value':'971','label':'+971 - Emirados Árabes Unidos'},
    ];
    
    

    const formEdit = {
        action: `/m3002/${params.md}/contact/${params.hash}`,
        method: (params.hash === "new") ? 'POST' : 'PUT',
        data: data,
        onSubmitSuccess,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: '6', autoFocus: true },
            { name: 'contact_name', type: 'text', label: 'Nome no Whatsapp', col: '6', disabled: true },
            { name: 'country_code', type: 'select', label: 'Código País', col: '1',options: optionsCountryCode },
            { name: 'phone_number', type: 'text', label: 'Telefone', col: '4', mask: '(99) 99999-9999', required:'Telefone do contato não informado!' },
            { name: 'wa_id', type: 'text', label: 'Identificação Whatsapp', col: '3', disabled: true },
            { name: 'last_message_status', type: 'text', label: 'Status da última mensagem', col: '4', disabled: true },
            { name: 'last_contacted_at', type: 'datetime', label: 'Último atualização mensagem', col: '12', disabled: true },
            { name: 'email', type: 'email', label: 'E-mail', col: '12' },
            { name: 'notes', type: 'textarea', label: 'Anotações', col: '12' },
        ],
        submits: [
            { label: 'Salvar', class: "btn btn-primary" }
        ],
    };


    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    return (
        <>
            <FormPadrao key={params.hash} {...formEdit} />
        </>
    );
}

export default ChatWppContactDetails;