import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import bg_holder from '../../../utils/icon/corner-4.png'
import useScrollSpy from 'react-use-scrollspy';
import useLoadData from "../../../../data/swr/useLoadData";
import RequestError from "../../../utils/request/RequestError";
import RequestLoading from "../../../utils/request/RequestLoading";
import FormPadrao from "../../../utils/form";



const ColaboradorById = props => {

    const { cid } = useParams();
    const { data, error, isLoading } = useLoadData(`/empresa/colaboradores/${cid}`);
    const [optionsJobs, setOptionsJobs] = useState([{ label: "Selecione...", value: '' }]);

    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -150
    });

    function onSubmitSuccess() {
        mutate(`/empresa/colaboradores/${cid}`);
    }

    useEffect(() => {
        var options = [{ label: "Selecione...", value: '', disabled: true }];
        data?.jobs?.map(j => {
            options.push({
                label: j?.name,
                value: j?.id
            });
            return true;
        })

        setOptionsJobs(options);
    }, [data?.jobs]);



    const formEdit = {
        formType: 'prepend',
        action: `/empresa/colaboradores/${cid}`,
        method: 'PUT',
        data: data?.user,
        drive: data?.files,
        onSubmitSuccess,
        groups: [
            {
                id: "info",
                ref: sectionRefs[0],
                header: 'Informações Básicas',
                fields: [
                    { name: 'active', type: 'select', label: 'Situação', options: [{ label: 'Ativo', value: true }, { label: 'Inativo', value: false }] },
                    { name: 'name', type: 'text', label: 'Nome', required: 'Nome não informado!', autoFocus: true },
                    { name: 'surname', type: 'text', label: 'Apelido' },
                    { name: 'document_number', type: 'text', label: 'Documento', mask: '999.999.999-99' },
                    { name: 'register_number', type: 'text', label: 'RG' },
                    { name: 'register_issuer', type: 'text', label: 'RG - Orgão Emissor' },
                    { name: 'register_date', type: 'date', label: 'RG - Data Emissão' },
                    { name: 'birth', type: 'date', label: 'Data de Nascimento' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
            {
                id: "contato",
                ref: sectionRefs[1],
                header: 'Contatos',
                fields: [
                    { name: 'email', type: 'email', label: 'E-mail' },
                    { name: 'phone', type: 'text', label: 'Telefone', mask: '(99) 99999-9999' },
                    { name: 'whatsapp', type: 'text', label: 'Whatsapp', mask: '(99) 99999-9999' },
                    { name: 'facebook', type: 'text', label: 'Facebook' },
                    { name: 'instagram', type: 'text', label: 'Instagram' },
                    { name: 'linkedin', type: 'text', label: 'Linkedin' },
                    { name: 'note_name', type: 'text', label: 'Recado - Nome' },
                    { name: 'note_email', type: 'email', label: 'Recado - E-mail' },
                    { name: 'note_phone', type: 'text', label: 'Recado - Telefone', mask: '(99) 99999-9999' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
            {
                id: "endereco",
                ref: sectionRefs[2],
                header: 'Endereço',
                fields: [
                    { name: 'postal_code', type: 'text', label: 'CEP', mask: '99999-999' },
                    { name: 'city', type: 'text', label: 'Cidade' },
                    { name: 'uf', type: 'text', label: 'Estado' },
                    { name: 'country', type: 'text', label: 'País' },
                    { name: 'district', type: 'text', label: 'Bairro' },
                    { name: 'number', type: 'text', label: 'Número' },
                    { name: 'ibge', type: 'number', label: 'IBGE' },
                    { name: 'address', type: 'text', label: 'Endereço' },
                    { name: 'complement', type: 'textarea', label: 'Complemento' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
            {
                id: "profissional",
                ref: sectionRefs[3],
                header: 'Perfil Profissional',
                fields: [
                    { name: 'occupation_id', type: 'select', label: 'Cargo', options: optionsJobs },
                    { name: 'admission', type: 'date', label: 'Data de Admissão' },
                    { name: 'resignation', type: 'date', label: 'Data da Demissão' },
                    { name: 'access', type: 'select', label: 'Acesso ao Sistema', options: [{ label: "Sim", value: true }, { label: "Não", value: false }] },
                    { name: 'password', type: 'password', label: 'Senha' },
                    { name: 'observation', type: 'textarea', label: 'Observações' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
            {
                id: "drive",
                ref: sectionRefs[4],
                fields: [
                    { name: 'documents', type: 'drive', label: 'Drive Pessoal' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
        ],
    };




    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    return (
        <>
            <Card className="mb-3">
                <div className="bg-holder d-none d-lg-block bg-card" style={{ "backgroundImage": `url(${bg_holder})` }}></div>
                <Card.Body className="card-body position-relative">
                    <Row className="justify-content-between align-items-center">
                        <Col lg="8">
                            <h3>{data?.user?.name}</h3>
                            <p className="mb-0">
                                <small>Cadastro atualizado em {data?.user?.updated_at}.</small>
                            </p>
                        </Col>
                        <Col lg="auto">
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row>
                <Col lg="3">
                    <Card className="sticky-top py-4" style={{ "top": "var(--falcon-top-nav-height)" }}>
                        <ul className="scrollspy" id="account" role="tablist">
                            <li className={`${activeSection === 0 ? 'active' : ''}`}>
                                <a href="#info" className="d-flex align-items-center py-3">
                                    <span className="fas fa-user"></span>&nbsp;Informações
                                </a>
                            </li>
                            <li className={`${activeSection === 1 ? 'active' : ''}`}>
                                <a href="#contato" className="d-flex align-items-center py-3">
                                    <span className="fab fa-whatsapp-square"></span>&nbsp;Contatos
                                </a>
                            </li>
                            <li className={`${activeSection === 2 ? 'active' : ''}`}>
                                <a href="#endereco" className="d-flex align-items-center py-3">
                                    <span className="fas fa-map-marker-alt"></span>&nbsp;Endereço
                                </a>
                            </li>
                            <li className={`${activeSection === 3 ? 'active' : ''}`}>
                                <a href="#profissional" className="d-flex align-items-center py-3">
                                    <span className="fas fa-user-tag"></span>&nbsp;Profissional
                                </a>
                            </li>
                            <li className={`${activeSection === 4 ? 'active' : ''}`}>
                                <a href="#drive" className="d-flex align-items-center py-3">
                                    <span className="fas fa-cloud-upload-alt"></span>&nbsp;Drive
                                </a>
                            </li>
                        </ul>
                    </Card>
                </Col>
                <Col lg="9">
                    <div data-spy="scroll" data-bs-target="#account" data-bs-smooth-scroll="true" data-bs-root-margin="0px 0px -40%" className="--bg-body-tertiary" tabIndex="0">
                        <FormPadrao {...formEdit} />
                    </div>
                </Col>
            </Row >
        </>
    );
}


export default ColaboradorById;