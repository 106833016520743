import React from "react";


const AvatarName = props => {

    const name = props?.surname || props?.name || props?.fantasy;
    const active = props?.active;
    if (!name) return "<!-- Nome não definido-->";

    var first = name.split(' ').shift().substr(0, 1);
    var last = name.split(' ').pop().substr(0, 1);
    if (name.indexOf(' ') === -1) last = '';

    return (
        <div className={`avatar avatar-${props?.size ? props?.size : 'xl'}`}>
            {
                props?.avatar ? (
                    <img className="rounded-circle img-thumbnail shadow-sm" src={props?.avatar} alt={props?.name} />
                ) : (
                    <div className={`avatar-name rounded-circle ${active ? 'bg-success' : ''}`} title={name}>
                        <span>{first}{last}</span>
                    </div>
                )
            }
        </div>
    );
}

export default AvatarName;