import React from "react";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Outlet } from 'react-router-dom';


const LayoutChatWppConfig = props => {
    const params = useParams();
    const baseUrl = "/m3002/"+params.md;
    return (
        <>
        <Row>
            <Col lg="12 mb-4">
                <Card>
                    <Card.Body>
                        <h4 className="text-900">Configurações da Aplicação</h4>
                        <h6 className="text-muted mb-0">Defina as parametrizações necessárias</h6>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg="2">
                <Nav variant="pills" defaultActiveKey={1} className="flex-column">
                    <Nav.Item>
                        <Nav.Link eventKey={1} as={"span"}>
                            <Link to={baseUrl+"/config/conta"}>
                                Conta 
                            </Link>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={2} as={"span"}>
                            <Link to={baseUrl+"/config/etapas"}>Atendimento</Link>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={3} as={"span"}>
                            <Link to={baseUrl+"/config/Templates"}>Templates</Link>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Col>
            <Col>
                <Card>
                    <Card.Body>
                        <Outlet/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </>
    );
}

export default LayoutChatWppConfig;