import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';

const HeaderChatMessage = (props) => {
  const params = useParams();

  const [contact, setContact] = useState({});
  useEffect(() => {
    if (props && props.contact) setContact(props.contact);
  }, [props]);

  return (
    <div key={params.hash} className="row flex-between-center">
      <div className="col-6 col-sm-8 d-flex align-items-center">
        <div className="min-w-0">
          <h5 className="mb-0 text-truncate fs-0">
            {contact?.name} {contact?.phone_number}
          </h5>
          <div className="fs--2 text-400">{contact?.status}</div>
          {contact && contact.conversation ? (
            <div className="fs--2 text-400">
              {contact.is_session_active ? 'Sessão encerra ' : 'Sessão encerrada em '}
              <Moment fromNowDuring format="DD/MM/YYYY [às] HH:mm">
                {contact.conversation.expiration_timestamp}
              </Moment>
            </div>
          ) : (
            <div className="fs--2 text-400">Envie a primeira mensagem ao contato</div>
          )}
        </div>
      </div>
      <div className="col-auto">{props.children}</div>
    </div>
  );
};

export default HeaderChatMessage;
