import React, { useEffect, useState } from "react";
import FormPadrao from "../../../../../utils/form";
import { useOutletContext } from 'react-router-dom';
import RequestError from "../../../../../utils/request/RequestError";
import RequestLoading from "../../../../../utils/request/RequestLoading";
import useLoadData from "../../../../../../data/swr/useLoadData";
import { Row, Col } from 'react-bootstrap';
import { mutate } from 'swr';

const DadosFornecedor = props => {

    const context = useOutletContext();

    const [type, setType] = useState(false);
    const [fornecedor, setFornecedor] = useState([]);
    const { data, error, isLoading } = useLoadData(`${context.baseUrl}`);

    const optionsPorte = [{ label: 'Selecione...', value: '', seleted: true, disabled: true }, { label: 'Micro', value: 'Micro' }, { label: 'Pequena', value: 'Pequena' }, { label: 'Média', value: 'Média' }, { label: 'Grande', value: 'Grande' }];
    const fieldsPF = [
        { name: 'name', type: 'text', label: 'Nome', col: '8', required: 'Nome não informado!' },
        { name: 'surname', type: 'text', label: 'Apelido', col: '4' },
        { name: 'document_number', type: 'text', label: 'CPF', col: '8', mask: '999.999.999-99' },
        { name: 'birth', type: 'date', label: 'Data Nascimento', col: '4' },

        { name: 'register_number', type: 'text', label: 'RG', col: '4' },
        { name: 'register_issuer', type: 'text', label: 'RG - Orgão Emissor', col: '4' },
        { name: 'register_date', type: 'date', label: 'RG - Data Emissão', col: '4' },
    ];
    const fieldsPJ = [
        { name: 'fantasy', type: 'text', label: 'Nome Fantasia', col: '12', required: 'Nome não informado!' },
        { name: 'name', type: 'text', label: 'Razão social', col: '12', required: 'Razão social não informado!' },

        { name: 'document_number', type: 'text', label: 'CNPJ', col: '4', mask: '99.999.999/9999-99' },
        { name: 'registration_number', type: 'text', label: 'Inscrição Estadual', col: '4' },
        { name: 'company_size', type: 'select', label: 'Porte da Empresa', col: '4', options: optionsPorte },
        { name: 'activity_primary', type: 'text', label: 'Atividade Primária', col: '12' },

    ];

    useEffect(() => {
        if (data?.fornecedor) setFornecedor({ ...data.fornecedor });
        if (data?.fornecedor) setType(data.fornecedor.type || false);
    }, [data]);


    const formEdit = {
        action: context.baseUrl,
        method: 'PUT',
        fields: [],
        data: fornecedor,
        onSubmitSuccess: () => mutate(`${context.baseUrl}`),
        submits: [
            { label: 'Salvar', class: "btn btn-primary" }
        ],
    };

    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    return (
        <div className="">
            <Row className="justify-content-center">
                <Col lg="8">
                    <div className={`${type === "PJ" ? 'show' : 'd-none'} fade`}>
                        <FormPadrao {...formEdit} fields={fieldsPJ} />
                    </div>
                    <div className={`${type === "PF" ? 'show' : 'd-none'} fade`}>
                        <FormPadrao {...formEdit} fields={fieldsPF} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default DadosFornecedor;
