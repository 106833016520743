import React from "react";
import { Col, Nav, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Outlet } from 'react-router-dom';
import useLoadData from "../../../data/swr/useLoadData";
import RequestError from "../../utils/request/RequestError";
import RequestLoading from "../../utils/request/RequestLoading";
import AvatarName from "../../adm/utils/AvatarName";
import { formatCNPJ, formatTelefone } from "../../../scripts/utils";
import { Link } from "react-router-dom";


const LayoutEmpresa = props => {

    const { data, error, isLoading } = useLoadData('/empresa/layout');
    const company = data?.company;


    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    return (
        <>
            <div className="page-header mb-3">
                <div className="d-flex mb-4">
                    <div className="position-relative mb-2">
                        <div className="avatar avatar-4xl">
                            <div className="h-100 w-100 rounded-circle overflow-hidden position-relative">
                                <img className="d-block" width="100" data-dz-thumbnail="data-dz-thumbnail" src="https://htmlstream.com/preview/front-dashboard-v2.1.1/assets/svg/brands/guideline-icon.svg" alt="" />
                                <input className="d-none" id="profile-image" type="file" />
                                <label className="mb-0 overlay-icon d-flex flex-center" htmlFor="profile-image">
                                    <span className="bg-holder overlay overlay-0"></span>
                                    <span className="z-1 text-white dark__text-white text-center fs--1">
                                        <span className="fas fa-camera"></span>
                                        <span className="d-block">Update</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1 ms-4">
                        <Row>
                            <Col className="mb-3 mb-lg-0">
                                <h4 className="mb-1">
                                    {company?.fantasy} <br />
                                    {/* <small className="fs-1">{company?.name}</small> */}
                                </h4>
                                <h5 className="fs-0 fw-normal">{formatCNPJ(company?.document_number)}</h5>
                                <hr className="mt-2 mb-1" />
                                <small className="d-block">
                                    {company?.responsible_position} - {company?.responsible_name} <br />
                                    {company?.responsible_email} <br />
                                    {formatTelefone(company?.responsible_phone)}
                                </small>
                            </Col>
                            <Col lg="auto">
                                <div className="small fw-bolder mb-2">USUÁRIOS</div>
                                <div className="d-flex justify-content-end">
                                    <div className="avatar-group avatar-circle me-3-">
                                        {
                                            data?.users?.map(a => {
                                                return (
                                                    <OverlayTrigger
                                                        key={a?.id}
                                                        placement='left'
                                                        overlay={
                                                            <Tooltip id={`tooltip-${a?.id}`}>
                                                                {a?.name}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className="avatar">
                                                            <AvatarName {...a} />
                                                        </div>
                                                    </OverlayTrigger>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Nav variant="tabs">
                    <Nav.Item>
                        <Link to={{ pathname: "/empresa/cadastro" }} className={`nav-link`}>
                            Cadastro
                        </Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to={{ pathname: "/empresa/setores" }} className={`nav-link`}>
                            Setores
                        </Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to={{ pathname: "/empresa/cargos" }} className={`nav-link`}>
                            Cargos
                        </Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to={{ pathname: "/empresa/colaboradores" }} className={`nav-link`}>
                            Colaboradores
                        </Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div className="layout-container-empresa">
                <Outlet />
            </div>
        </>
    );

}

export default LayoutEmpresa;