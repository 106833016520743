import React from 'react';

const DashboardAdm = () => {
    return (
        <>
            <div className='card'>
                <div className="card-body">
                    Dash
                </div>
            </div>
        </>
    );
};

export default DashboardAdm;
