import React, { useContext } from 'react';
import AppContext from '../../context/Context';
import moment from 'moment';

const Footer = () => {

    const { contextValue } = useContext(AppContext);
    const login = moment.unix(contextValue?.iat);

    return (
        <footer className="footer">
            <div className='row justify-content-lg-between justify-content-center text-center fs--1 mt-4 mb-3'>
                <div className="col-auto">
                    <p className="mb-0 text-600">
                        Copyright {new Date().getFullYear()} &copy; Ezequias Candeia. Todos os direitos reservados.
                    </p>
                </div>
                <div className="col-auto">
                </div>
                <div className="col-auto">
                    <p className="mb-0 text-600">Acesso realizado em {login.format('DD/MM/YYYY')} às {login.format('HH:mm:ss')} IP {contextValue?.ip}</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
