import React, { useState } from "react";
import FormPadrao from "../../../utils/form";
import { Button, Modal } from "react-bootstrap";
import { mutate } from "swr";

const ModalNewColab = props => {

    const [modalShow, setModalShow] = useState(false);

    function onSubmitSuccess() {
        setModalShow(false);
        mutate('/empresa/colaboradores');
    }

    const formEdit = {
        // formType: 'prepend',
        action: '/empresa/colaboradores',
        method: 'POST',
        onSubmitSuccess,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: '12', required: 'Nome não informado!', autoFocus: true },
            { name: 'document_number', type: 'text', label: 'Documento', col: '12', mask: "999.999.999-99" },
            { name: 'email', type: 'email', label: 'E-mail', col: '12', required: 'E-mail não informado!' },
            { name: 'phone', type: 'text', label: 'Telefone', col: '12', mask: "(99) 99999-9999" },
        ],
        submits: [
            { label: 'Cadastrar', class: "btn btn-primary" }
        ],
    };

    return (
        <>
            <Button className={props?.button ? props?.button : 'btn btn-falcon-primary'} variant="" onClick={() => setModalShow(true)}>
                <span className="fas fa-plus"></span>
                <span className="d-none d-sm-inline-block ms-1">Novo Colaborador</span>
            </Button>
            <Modal
                show={modalShow}
                onHide={() => { setModalShow(false); }}
                size="md"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Novo Colaborador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalNewColab;