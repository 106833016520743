import React from "react";
import { Outlet, useParams } from 'react-router-dom';
import { Nav, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";

const LayoutCadastroFornecedor = props => {

    const params = useParams();

    const path = `/m3001/${params.md}/gestao-contas/fornecedor/${params.fid}`;
    const baseUrl = `/m3001/${params.md}/fornecedores/${params.fid}`;

    return (
        <>
            <Nav variant="tabs">
                <Nav.Item>
                    <Link to={{ pathname: path }} className={`nav-link`}>
                        Informações do Cadastro
                    </Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to={{ pathname: path + '/dados' }} className={`nav-link`}>
                        Dados da Conta
                    </Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to={{ pathname: path + '/cartoes' }} className={`nav-link`}>
                        Cartões
                    </Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to={{ pathname: path + '/enderecos' }} className={`nav-link`}>
                        Endereços
                    </Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to={{ pathname: path + '/comunicacoes' }} className={`nav-link`}>
                        Comunicações
                    </Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to={{ pathname: path + '/privacidade' }} className={`nav-link`}>
                        Privacidade
                    </Link>
                </Nav.Item>
            </Nav>
            <Card>
                <Card.Body>
                    <Outlet context={{ baseUrl, path, md: params.id, cmodule: params.fid }} />
                </Card.Body>
            </Card>
        </>
    );
}

export default LayoutCadastroFornecedor;
