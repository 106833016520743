import React from 'react';
import { formatTelefone } from '../../../../../../scripts/utils';

const LayoutMessageReaction = ({ message, isSent }) => {
  const reaction = message.reaction;
  if (!reaction) return null;
  if (isSent) {
    return (
      <span className="ms-2 msg-reaction" style={{ position: 'absolute', bottom: '-7px', right: '-7px' }}>
        {reaction.content}
      </span>
    );
  }
  return (
    <span className="ms-2 msg-reaction" style={{ position: 'absolute', bottom: '-7px', left: '-7px' }}>
      {reaction.content}
    </span>
  );
};

const LayoutMessageContext = ({ message, isSent }) => {
  const context = message.context_message;
  if (!context) return null;
  return (
    <div className="msg-context shadow-sm rounded" style={{ borderLeft: 'solid 5px red', padding: '5px 4px', marginBottom: '5px' }}>
      <small className="d-block">
        {'+' + formatTelefone(context.wa_id)} - <span className="text-muted">{context.contact_name}</span>
      </small>
      <LayoutMessage message={context} isSent={isSent} />
    </div>
  );
};

const LayoutMessageUnsupported = ({ message, isSent }) => (
  <>
    <LayoutMessageContext message={message} isSent={isSent} />
    <span className={'msg-' + message.message_type}>Mensagem não suportada!</span>
    <LayoutMessageReaction message={message} isSent={isSent} />
  </>
);

const LayoutMessageText = ({ message, isSent }) => (
  <>
    <LayoutMessageContext message={message} isSent={isSent} />
    <span className="msg-text">{message.content}</span>
    <LayoutMessageReaction message={message} isSent={isSent} />
  </>
);

const LayoutMessageImage = ({ message, isSent }) => (
  <>
    <LayoutMessageContext message={message} isSent={isSent} />
    <div className="msg-image">
      <img className="rounded img-fluid" src={process.env.REACT_APP_BASEURL + message.file_path} alt={message.content} />
      {message.content && <p className="mb-0 mt-1">{message.content}</p>}
    </div>
    <LayoutMessageReaction message={message} isSent={isSent} />
  </>
);

const LayoutMessageVideo = ({ message, isSent }) => (
  <>
    <LayoutMessageContext message={message} isSent={isSent} />
    <div className="msg-video">
      {message.content && <p>{message.content}</p>}
      <video controls className="rounded">
        <source src={process.env.REACT_APP_BASEURL + message.file_path} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <LayoutMessageReaction message={message} isSent={isSent} />
  </>
);

const LayoutMessageAudio = ({ message, isSent }) => (
  <>
    <LayoutMessageContext message={message} isSent={isSent} />
    <div className="msg-audio">
      <audio controls>
        <source src={process.env.REACT_APP_BASEURL + message.file_path} type="audio/ogg" />
        Your browser does not support the audio element.
      </audio>
    </div>
    <LayoutMessageReaction message={message} isSent={isSent} />
  </>
);

const LayoutMessageDocument = ({ message, isSent }) => (
  <>
    <LayoutMessageContext message={message} isSent={isSent} />
    <div className="msg-document">
      {message.content && <p>{message.content}</p>}
      <a href={process.env.REACT_APP_BASEURL + message.file_path} target="_blank" rel="noopener noreferrer">
        Baixar Documento
      </a>
    </div>
    <LayoutMessageReaction message={message} isSent={isSent} />
  </>
);

const LayoutMessageSticker = ({ message, isSent }) => (
  <>
    <LayoutMessageContext message={message} isSent={isSent} />
    <div className="msg-sticker">
      <img className="sticker" width={190} height={190} src={process.env.REACT_APP_BASEURL + message.file_path} alt="Sticker" />
    </div>
    <LayoutMessageReaction message={message} isSent={isSent} />
  </>
);

const LayoutMessageLocation = ({ message, isSent }) => (
  <>
    <LayoutMessageContext message={message} isSent={isSent} />
    <div className="msg-location">
      <p>Localização: {message.content}</p>
      <a href={`https://www.google.com/maps/search/?api=1&query=${message.latitude},${message.longitude}`} target="_blank" rel="noopener noreferrer">
        Ver no Google Maps
      </a>
    </div>
    <LayoutMessageReaction message={message} isSent={isSent} />
  </>
);

const LayoutMessage = ({ message, isSent }) => {
  if (!message || !message.message_type) return null;

  switch (message.message_type) {
    case 'text':
      return <LayoutMessageText message={message} isSent={isSent} />;
    case 'template':
      return <LayoutMessageText message={message} isSent={isSent} />;
    case 'image':
      return <LayoutMessageImage message={message} isSent={isSent} />;
    case 'video':
      return <LayoutMessageVideo message={message} isSent={isSent} />;
    case 'audio':
      return <LayoutMessageAudio message={message} isSent={isSent} />;
    case 'document':
      return <LayoutMessageDocument message={message} isSent={isSent} />;
    case 'sticker':
      return <LayoutMessageSticker message={message} isSent={isSent} />;
    case 'location':
      return <LayoutMessageLocation message={message} isSent={isSent} />;
    case 'reaction':
      return <LayoutMessageReaction message={message} isSent={isSent} />;
    case 'unsupported':
    default:
      return <LayoutMessageUnsupported message={message} isSent={isSent} />;
  }
};

export default LayoutMessage;
