import React, { useState } from "react";
import FormPadrao from "../../../utils/form";
import { Button, Modal } from "react-bootstrap";
import { mutate } from "swr";

const ModalNewSetor = props => {

    const [modalShow, setModalShow] = useState(false);

    function onSubmitSuccess() {
        setModalShow(false);
        mutate('/empresa/setores');
    }

    const formEdit = {
        // formType: 'prepend',
        action: '/empresa/setores',
        method: 'POST',
        onSubmitSuccess,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: '8', required: 'Nome não informado!', autoFocus: true },
            { name: 'code', type: 'text', label: 'Código', col: '4' },
            { name: 'email', type: 'email', label: 'E-mail', col: '12' },
            { name: 'phone', type: 'text', label: 'Telefone', col: '6', mask: '(99) 99999-9999' },
            { name: 'whatsapp', type: 'text', label: 'Whatsapp', col: '6', mask: '(99) 99999-9999' },
        ],
        submits: [
            { label: 'Cadastrar', class: "btn btn-primary" }
        ],
    };

    return (
        <>
            <Button className={props?.button ? props?.button : 'btn btn-falcon-primary'} variant="" onClick={() => setModalShow(true)}>
                <span className="fas fa-plus"></span>
                <span className="d-none d-sm-inline-block ms-1">Novo Setor</span>
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Novo Setor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalNewSetor;