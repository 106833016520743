import React, { useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useLoadData from "../../../data/swr/useLoadData";
import RequestError from "../../utils/request/RequestError";
import RequestLoading from "../../utils/request/RequestLoading";
import FormPadrao from "../../utils/form";
import { mutate } from "swr";
import bg_holder from '../../utils/icon/corner-4.png'
import useScrollSpy from 'react-use-scrollspy';
import AvatarName from "../utils/AvatarName";
import { formatCPF } from "../../../scripts/utils";
import AcessSchemaClient from "./AccessSchemaClient";
import { Link } from "react-router-dom";
import ModalClientModules from "./ModalClientModules";
import { BadgeActive, BadgeInactive } from "../utils/Badge";



const ClientById = props => {

    const { cid } = useParams();
    const { data, error, isLoading } = useLoadData(`/adm/clients/${cid}`);

    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -150
    });

    function onSubmitSuccess() {
        mutate(`/adm/clients/${cid}`);
    }


    const formEdit = {
        formType: 'prepend',
        action: `/adm/clients/${cid}`,
        method: 'PUT',
        data: data?.client,
        onSubmitSuccess,
        groups: [
            {
                id: "info",
                ref: sectionRefs[0],
                header: 'Informações Básicas',
                fields: [
                    { name: 'active', type: 'select', label: 'Situação', options: [{ label: 'Ativo', value: true }, { label: 'Inativo', value: false }] },
                    { name: 'name', type: 'text', label: 'Razão Social', required: 'Nome não informado!', autoFocus: true },
                    { name: 'fantasy', type: 'text', label: 'Nome Fantasia', required: 'Nome não informado!' },
                    { name: 'document_number', type: 'text', label: 'Documento', mask: '99.999.999/9999-99', disabled: true },
                    { name: 'registration_number', type: 'text', label: 'Inscrição Estadual' },
                    { name: 'activity_primary', type: 'text', label: 'Atividade Principal' },
                    { name: 'phone', type: 'text', label: 'Telefone', mask: '(99) 99999-9999' },
                    { name: 'email', type: 'email', label: 'E-mail' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
            {
                id: "endereco",
                ref: sectionRefs[1],
                header: 'Endereço',
                fields: [
                    { name: 'postal_code', type: 'text', label: 'CEP', mask: '99999-999' },
                    { name: 'city', type: 'text', label: 'Cidade' },
                    { name: 'uf', type: 'text', label: 'Estado' },
                    { name: 'country', type: 'text', label: 'País' },
                    { name: 'district', type: 'text', label: 'Bairro' },
                    { name: 'number', type: 'text', label: 'Número' },
                    { name: 'ibge', type: 'number', label: 'IBGE' },
                    { name: 'address', type: 'text', label: 'Endereço' },
                    { name: 'complement', type: 'textarea', label: 'Complemento' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
            {
                id: "responsavel",
                ref: sectionRefs[2],
                header: 'Responsável',
                fields: [
                    { name: 'responsible_name', type: 'text', label: 'Nome' },
                    { name: 'responsible_position', type: 'text', label: 'Cargo' },
                    { name: 'responsible_phone', type: 'text', label: 'Telefone', mask: '(99) 99999-9999' },
                    { name: 'responsible_email', type: 'email', label: 'E-mail' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            },
            {
                id: "financeiro",
                ref: sectionRefs[3],
                header: 'Financeiro',
                fields: [
                    { name: 'finances_name', type: 'text', label: 'Nome' },
                    { name: 'finances_phone', type: 'text', label: 'Telefone', mask: '(99) 99999-9999' },
                    { name: 'finances_email', type: 'email', label: 'E-mail' },
                ],
                submit: { label: 'Salvar', class: "btn btn-sm btn-primary" }
            }
        ],
    };




    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    return (
        <>
            <Card className="mb-3">
                <div className="bg-holder d-none d-lg-block bg-card" style={{ "backgroundImage": `url(${bg_holder})` }}></div>
                <Card.Body className="card-body position-relative">
                    <Row className="justify-content-between align-items-center">
                        <Col lg="8">
                            <h3>{data?.client?.fantasy}</h3>
                            <p className="mb-0">
                                <small>Cadastro atualizado em {data?.client?.updated_at}.</small>
                            </p>
                        </Col>
                        <Col lg="auto">
                            <AcessSchemaClient className="btn btn-falcon-primary" schema={data?.client?.id} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row>
                <Col lg="3">
                    <Card className="sticky-top py-4" style={{ "top": "var(--falcon-top-nav-height)" }}>
                        <ul className="scrollspy" id="account" role="tablist">
                            <li className={`${activeSection === 0 ? 'active' : ''}`}>
                                <a href="#info" className="d-flex align-items-center py-3">
                                    <span className="far fa-building"></span>&nbsp;Informações
                                </a>
                            </li>
                            <li className={`${activeSection === 1 ? 'active' : ''}`}>
                                <a href="#endereco" className="d-flex align-items-center py-3">
                                    <span className="fas fa-map-marker-alt"></span>&nbsp;Endereço
                                </a>
                            </li>
                            <li className={`${activeSection === 2 ? 'active' : ''}`}>
                                <a href="#responsavel" className="d-flex align-items-center py-3">
                                    <span className="far fa-user"></span>&nbsp;Responsável
                                </a>
                            </li>
                            <li className={`${activeSection === 3 ? 'active' : ''}`}>
                                <a href="#financeiro" className="d-flex align-items-center py-3">
                                    <span className="far fa-money-bill-alt"></span>&nbsp;Financeiro
                                </a>
                            </li>
                            <li className={`${activeSection === 4 ? 'active' : ''}`}>
                                <a href="#usuarios" className="d-flex align-items-center py-3">
                                    <span className="fas fa-users"></span>&nbsp;Usuários
                                </a>
                            </li>
                            <li className={`${activeSection === 5 ? 'active' : ''}`}>
                                <a href="#modulos" className="d-flex align-items-center py-3">
                                    <span className="fas fa-boxes"></span>&nbsp;Módulos
                                </a>
                            </li>
                        </ul>
                    </Card>
                </Col>
                <Col lg="9">
                    <div data-spy="scroll" data-bs-target="#account" data-bs-smooth-scroll="true" data-bs-root-margin="0px 0px -40%" className="--bg-body-tertiary" tabIndex="0">
                        {/* <InputLabelPrepend checked={data?.client?.active} type="checkbox" label="Situação" value="1" register={{ ...register('active') }} disabled={disabled} /> */}
                        <FormPadrao {...formEdit} />

                        <div className="" id="usuarios" ref={sectionRefs[4]}>
                            <Card className="mb-3">
                                <Card.Header>
                                    Usuários
                                </Card.Header>
                                <Card.Body>
                                    <div className="table-responsive scrollbar">
                                        <table className="table table-sm table-striped fs--1 mb-0 overflow-hidden">
                                            <thead className="bg-200 text-900">
                                                <tr>
                                                    <th className="col">Pessoa</th>
                                                    <th className="col">Documento</th>
                                                    <th className="col">E-mail</th>
                                                    <th className="col">Cadastro</th>
                                                    <th className="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody className="list" id="table-ticket-body">
                                                {
                                                    data?.users?.map(u => {
                                                        return (
                                                            <tr className="align-middle" key={u?.id}>
                                                                <td className="text-nowrap py-2">
                                                                    <div className="d-flex align-items-center">
                                                                        <AvatarName {...u} />
                                                                        <h6 className="ms-2 mb-0">
                                                                            <span className="text-900">
                                                                                {u?.name}
                                                                            </span>
                                                                        </h6>
                                                                    </div>
                                                                </td>
                                                                <td className="text-nowrap py-2">
                                                                    {formatCPF(u?.document_number)}
                                                                </td>
                                                                <td className="text-nowrap py-2">
                                                                    {u?.email}
                                                                </td>
                                                                <td className="text-nowrap py-2">
                                                                    {u?.created_at}
                                                                </td>
                                                                <td className="text-nowrap py-2">
                                                                    <div className="dropdown font-sans-serif position-static">
                                                                        <button className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
                                                                            <span className="fas fa-ellipsis-h fs--1"></span>
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-end border py-0">
                                                                            <div className="py-2">
                                                                                {/* <ModalUserEdit uid={u?.id} />
                                                                                    <ModalUserDelete uid={u?.id} /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="" id="modulos" ref={sectionRefs[5]}>
                            <Card className="mb-3">
                                <Card.Header>
                                    <div className="row justify-content-between">
                                        <div className="col-lg-auto">Módulos</div>
                                        <div className="col-lg-auto"><ModalClientModules client={cid} onSubmitSuccess={onSubmitSuccess} /></div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="table-responsive scrollbar">
                                        <table className="table table-sm table-striped fs--1 mb-0 overflow-hidden">
                                            <thead className="bg-200 text-900">
                                                <tr>
                                                    <th className="col">Situação</th>
                                                    <th className="col">Nome</th>
                                                    <th className="col">Tipo</th>
                                                    <th className="col">Data/Hora</th>
                                                    <th className="col">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list" id="table-ticket-body">
                                                {
                                                    data?.modules?.map(md => {

                                                        return (
                                                            <tr key={md?.id}>
                                                                <td> {md?.active ? <BadgeActive /> : <BadgeInactive />} </td>
                                                                <td>{md?.name}</td>
                                                                <td><Link to={`/adm/modulos/${md?.module_id}`}>{md?.module}</Link></td>
                                                                <td>{md?.created_at}</td>
                                                                <td><ModalClientModules client={cid} module={md} onSubmitSuccess={onSubmitSuccess} /></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>


                    </div>
                </Col>
            </Row >
        </>
    );
}


export default ClientById;