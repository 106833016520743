import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './appCustomStyle.css';
import Layout from './layouts/Layout';
import { ToastContainer } from 'react-toastify';
import 'bootstrap';

import flatpickr from 'flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import 'flatpickr/dist/themes/material_green.css';

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/pt-br';
flatpickr.localize(Portuguese);
moment.locale('pt-br');

// Moment.globalFormat = 'DD/MM/YYYY HH:mm:ss';
Moment.globalFormat = 'D [de] MMMM [de] YYYY [às] HH:mm';
Moment.globalLocale = 'pt-Br';
Moment.globalElement = 'span';

const theme = localStorage.getItem('data-bs-theme') || 'light';
const App = () => {
    return (
        <>
            <Router basename={process.env.PUBLIC_URL}>
                <Layout />
            </Router>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar newestOnTop closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover theme={theme} />
        </>
    );
};

export default App;
