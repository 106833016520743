import React from "react";

const Avatar = props => {

    const size = props?.size ? props?.size : 'xl';
    const classN = `avatar avatar-${size} ${props?.className ? props.className : ''}`;
    const name = props?.name ? props.name : props?.label ? props.label : '';

    return (
        <>
            <div className="d-flex align-items-center">
                <div className={classN}>
                    {props.children}
                </div>
                <h6 className="ms-2 mb-0">
                    <span className="text-900">
                        {name}
                    </span>
                </h6 >
            </div>
        </>
    );
}

const AvatarName = props => {

    const classN = `avatar avatar-name rounded-circle ${!!props.active ? 'bg-success' : ''} `;
    const name = props.name || '';
    var first = name.split(' ').shift().substr(0, 1);
    var last = name.split(' ').pop().substr(0, 1);
    if (name.indexOf(' ') === -1) last = '';

    return (
        <>
            <div className={classN} title={name}>
                <span>{first}{last}</span>
            </div>
        </>
    );
}

Avatar.Name = AvatarName;
export default Avatar;

