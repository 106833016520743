import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import socket from '../../../../../../data/socket';
import { ShowErrorSocket } from '../../../../../utils/request/ShowError';

const TypeTemplate = ({ template, headerValues, bodyValues }) => {
  const params = useParams();
  const [contactHash, setContactHash] = useState(params.hash);
  const [modId, setModId] = useState(params.md);
  const navegate = useNavigate();

  useEffect(() => {
    setContactHash(params.hash);
    setModId(params.md);
    socket.on('send_message_error', (err) => ShowErrorSocket(err));
    return () => {
      socket.off('send_message_error', (err) => ShowErrorSocket(err));
    };
  }, [params]);

  const handleSubmit = async () => {
    try {
      const formData = {
        type: 'template',
        message_type: 'template',
        template: {
          name: template.name,
          language: {
            code: template.language,
          },
        },
        content: template.name,
      };

      const options = {
        contact_hash: contactHash,
        mod_id: modId,
      };
      socket.emit('send_message', formData, options);
      navegate(`/m3002/${params.md}/chat/${params.hash}`);
    } catch (error) {
      return false;
    }
  };

  const renderHeader = (header) => {
    if (header.type === 'HEADER' && header.format === 'TEXT') {
      if (!header.example) return <h5 className="card-title">{header.text}</h5>;
      const value = headerValues && headerValues[0] ? headerValues[0] : header.example.header_text[0];
      return <h5 className="card-title">{header.text.replace('{{1}}', value)}</h5>;
    }
    return null;
  };

  const renderBody = (body) => {
    if (body.type === 'BODY') {
      let bodyText = body.text;
      if (!body.example) return <p className="card-text mb-1">{bodyText}</p>;
      const values = bodyValues && bodyValues.length > 0 ? bodyValues : body.example.body_text[0];
      values.forEach((value, index) => {
        bodyText = bodyText.replace(`{{${index + 1}}}`, value);
      });
      return <p className="card-text mb-1">{bodyText}</p>;
    }
    return null;
  };

  const renderButtons = (buttons) => {
    if (buttons.type === 'BUTTONS') {
      return (
        <ul className="list-group list-group-flush border-top border-bottom">
          {buttons.buttons.map((button, index) => (
            <li className="list-group-item text-center" key={index} onClick={() => alert(button.text)}>
              <span className="card-link text-primary">
                <i className="fas fa-reply"></i> {button.text}
              </span>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return (
    <div className="card overflow-hidden mb-2 h-100" style={{ width: '17rem' }}>
      <div className="card-body p-2 pb-1 border-bottom">
        {template.components.map((component, index) => (
          <div key={index} className={`msg-${component.type.toLowerCase()}`}>
            {component.type === 'HEADER' && renderHeader(component)}
            {component.type === 'BODY' && renderBody(component)}
            {component.type === 'BUTTONS' && renderButtons(component)}
          </div>
        ))}
      </div>
      <div className="card-footer">
        <button type="button" className="btn-sm btn btn-primary w-100" onClick={() => handleSubmit()}>
          Enviar
        </button>
      </div>
    </div>
  );
};

export default TypeTemplate;
