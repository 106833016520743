import React from "react";

const HomeFornecedor = props => {


    return (
        <div className="p-4">
            Home
        </div>
    );
}

export default HomeFornecedor;
