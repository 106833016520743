import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Home from "../../../utils/img/people1.jpg";
import VerticalNav from './NavbarVerticalNav';
import useLoadData from '../../../../data/swr/useLoadData';


const NavbarVertical = () => {

    const [company, setCompany] = useState([]);
    const { data } = useLoadData('/empresa/layout');

    const HTMLClassList = document.getElementsByTagName('html')[0].classList;

    const { pathname } = useLocation();

    // MARCANDO O MENU COMO ATIVO
    useEffect(() => {
        document.querySelectorAll('[href].active').forEach(link => link.classList.remove('active'));
        document.querySelectorAll('[href="' + pathname + '"]').forEach(link => link.classList.add('active'));
    }, [pathname]);

    const clickButtonCollapse = () => {
        HTMLClassList.toggle('navbar-vertical-collapsed');
        localStorage.setItem('navbar-collapsed', !!document.querySelector('.navbar-vertical-collapsed'));
    }

    let time = null;
    const handleMouseEnter = () => {
        if (!!document.querySelector('.navbar-vertical-collapsed')) {
            time = setTimeout(() => {
                HTMLClassList.add('navbar-vertical-collapsed-hover');
            }, 100);
        }
    };
    const handleMouseLeave = () => {
        clearTimeout(time);
        HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };

    useEffect(() => {
        var collapsed = localStorage.getItem('navbar-collapsed');
        if (collapsed === "true" || collapsed === true) {
            HTMLClassList.add('navbar-vertical-collapsed');
        }
    }, [HTMLClassList]);

    useEffect(() => {
        setCompany(data?.company);
    }, [data]);

    return (
        <nav
            className='navbar navbar-light navbar-vertical navbar-expand-xl'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className='d-flex align-items-center'>
                <div className="toggle-icon-wrapper">
                    <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        onClick={clickButtonCollapse}
                    >
                        <span className="navbar-toggle-icon">
                            <span className="toggle-line"></span>
                        </span>
                    </button>
                </div>
                <Link className='navbar-brand' to={'/'}>
                    <div className='d-flex align-items-center py-3'>
                        {/* <img className="me-2" width={39} src={Home} alt="" /> */}
                        <span className='font-sans-serif'>{company?.fantasy}</span>
                    </div>
                </Link>
            </div>
            <div className='collapse navbar-collapse' id='navbarVerticalCollapse'>
                <div className='navbar-vertical-content scrollbar'>
                    <VerticalNav />
                </div>
            </div>
        </nav>
    );
};

export default NavbarVertical;
