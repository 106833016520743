import React, { useState, useEffect } from "react";
import FormPadrao from "../../../utils/form";
import { Button, Modal } from "react-bootstrap";
import { mutate } from "swr";
import useLoadData from "../../../../data/swr/useLoadData";
import RequestError from "../../../utils/request/RequestError";
import RequestLoading from "../../../utils/request/RequestLoading";


const ModalEditJobs = props => {

    const id = props?.id;
    const [modalShow, setModalShow] = useState(false);
    const [optionsJobs, setOptionsJobs] = useState([{ label: "Sem Superior", value: "" }]);
    const [optionsSectors, setOptionsSectors] = useState([{ label: "Selecione...", value: "", disabled: true }]);
    const { data, error, isLoading } = useLoadData(modalShow ? `/empresa/cargos/${id}` : null);

    function onSubmitSuccess() {
        setModalShow(false);
        mutate('/empresa/cargos');
    }

    useEffect(() => {
        var opt = [];
        opt.push({ label: "Sem Superior", value: "" });
        data?.jobs?.map(j => {
            opt.push({
                label: `${j?.id} - ${j?.name}`,
                value: j?.id
            })
            return true;
        });
        setOptionsJobs(opt);
    }, [data?.jobs]);

    useEffect(() => {
        var opt = [];
        opt.push({ label: "Selecione...", value: "", disabled: true });
        data?.sectors?.map(j => {
            opt.push({
                label: `${j?.id} - ${j?.name}`,
                value: j?.id
            })
            return true;
        });
        setOptionsSectors(opt);
    }, [data?.sectors]);

    const formEdit = {
        // formType: 'prepend',
        action: '/empresa/cargos/' + id,
        method: 'PUT',
        onSubmitSuccess,
        data: data?.job,
        dataModules: data?.modules,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: '5', required: 'Nome não informado!' },
            { name: 'code', type: 'text', label: 'Código', col: '4' },
            { name: 'active', type: 'select', label: 'Situação', col: '3', options: [{ label: "Ativo", value: true }, { label: "Inativo", value: false }] },
            { name: 'parent_id', type: 'select', label: 'Cargo Superior', col: '6', options: optionsJobs },
            { name: 'sector_id', type: 'select', label: 'Setor', col: '6', options: optionsSectors },
            { name: 'assignments', type: 'textarea', label: 'Atribuições', col: '12' },
        ],
        submits: [
            { label: 'Salvar', class: "btn btn-primary" }
        ],
    };

    return (
        <>
            <Button className={props?.button ? props?.button : 'btn btn-falcon-primary'} variant="" onClick={() => setModalShow(true)}>
                <span className="fa-solid fas fa-chevron-right"></span>
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Editando Cargo {data?.job?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <RequestError error={error} />}
                    {isLoading && <RequestLoading />}
                    {!isLoading && !error && < FormPadrao {...formEdit} />}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalEditJobs;



