import React, { useContext } from "react";
import Home from "../../../utils/img/people1.jpg";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../../../../context/Context";
import moment from "moment/moment";
import { mutate } from "swr";

const NavbarDropdownUser = props => {

    const { contextValue, setContextValue } = useContext(AppContext);
    const exp = moment.unix(contextValue?.exp);


    function logout() {
        localStorage.clear();
        setContextValue({ isAuthenticated: false });
    }

    return (
        <>
            <Link className="nav-link pe-0 ps-2" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="avatar avatar-xl">
                    <img className="rounded-circle" src={Home} alt="" />
                </div>
            </Link>
            <div className="dropdown-menu dropdown-caret dropdown-menu-end py-0">
                <div className="bg-white dark__bg-1000 rounded-2 py-2">
                    <ButtonClearSchema />
                    <button className="dropdown-item" type="button" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span> Sair
                    </button>
                    <span className="dropdown-item d-none">{exp.format('DD/MM/YYYY')} às {exp.format('HH:MM:ss')}</span>
                </div>
            </div>
        </>
    )
};

export default NavbarDropdownUser;

const ButtonClearSchema = props => {

    const { contextValue, setContextValue } = useContext(AppContext);
    const navigate = useNavigate();

    function clearSchemaAccess() {
        localStorage.removeItem(process.env.REACT_APP_NAMESCHEMA);
        setContextValue({ ...contextValue, schema: null });
        navigate('/');
        mutate('/navigation');
    }


    if (contextValue?.isAdmin && contextValue?.schema) {
        return (
            <button className="dropdown-item" type="button" onClick={clearSchemaAccess}>
                <span className="fas fa-chart-bar"></span> Área Administrativa
            </button>
        );
    }
    return '';
}