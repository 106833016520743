import React from "react";
import useLoadData from "../../../data/swr/useLoadData";
import RequestError from "../../utils/request/RequestError";
import RequestLoading from "../../utils/request/RequestLoading";
import AvatarName from "../utils/AvatarName";
import bg_holder from '../../utils/icon/corner-4.png'
import ModalUserAdd from "./ModalUserAdd";
import { formatCPF, formatTelefone } from '../../../scripts/utils';
import ModalUserEdit from "./ModalUserEdit";
import ModalUserDelete from "./ModalUserDelete";
import Moment from "react-moment";


const UsersADM = props => {

    const { data, error, isLoading } = useLoadData('/adm/users');
    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);

    return (
        <>
            <div className="card mb-3 d-none">
                <div className="bg-holder d-none d-lg-block bg-card" style={{ "backgroundImage": `url(${bg_holder})` }}></div>
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3>Usuários</h3>
                            <p className="mb-0">Usuário com acesso administrador.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card" id="">
                <div className="card-header">
                    <div className="row flex-between-center">
                        <div className="col-4 col-sm-auto d-flex align-items-center pe-0">
                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Usuários</h5>
                        </div>
                        <div className="col-8 col-sm-auto text-end-- ps-2">
                            <ModalUserAdd />
                        </div>
                    </div>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive scrollbar">
                        <table className="table table-sm table-striped fs--1 mb-0 overflow-hidden">
                            <thead className="bg-200 text-900">
                                <tr>
                                    <th className="col">Pessoa</th>
                                    <th className="col">Documento</th>
                                    <th className="col">Telefone</th>
                                    <th className="col">E-mail</th>
                                    <th className="col">Cadastro</th>
                                    <th className="col"></th>
                                </tr>
                            </thead>
                            <tbody className="list" id="table-ticket-body">
                                {
                                    data?.users?.map(u => {
                                        return (
                                            <tr className="align-middle" key={u?.id}>
                                                <td className="text-nowrap py-2">
                                                    <div className="d-flex align-items-center">
                                                        <AvatarName {...u} />
                                                        <h6 className="ms-2 mb-0">
                                                            <span className="text-900">
                                                                {u?.name}
                                                            </span>
                                                        </h6>
                                                    </div>
                                                </td>
                                                <td className="text-nowrap py-2">
                                                    {formatCPF(u?.document_number)}
                                                </td>
                                                <td className="text-nowrap py-2">
                                                    {formatTelefone(u?.phone)}
                                                </td>
                                                <td className="text-nowrap py-2">
                                                    {u?.email}
                                                </td>
                                                <td className="text-nowrap py-2">
                                                    <Moment>{u?.created_at}</Moment>
                                                </td>
                                                <td className="text-nowrap py-2">
                                                    <div className="dropdown font-sans-serif position-static">
                                                        <button className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
                                                            <span className="fas fa-ellipsis-h fs--1"></span>
                                                        </button>
                                                        <div className="dropdown-menu dropdown-menu-end border py-0">
                                                            <div className="py-2">
                                                                <ModalUserEdit uid={u?.id} />
                                                                <ModalUserDelete uid={u?.id} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UsersADM;