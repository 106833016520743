import React, { useState } from "react";
import FormPadrao from "../../../utils/form";
import { Button, Modal } from "react-bootstrap";
import { mutate } from "swr";
import RequestError from "../../../utils/request/RequestError";
import RequestLoading from "../../../utils/request/RequestLoading";
import useLoadData from "../../../../data/swr/useLoadData";

const ModalEditSetor = props => {

    const id = props?.id;
    const [modalShow, setModalShow] = useState(false);
    const { data, error, isLoading } = useLoadData(modalShow ? `/empresa/setores/${id}` : null);

    function onSubmitSuccess() {
        setModalShow(false);
        mutate('/empresa/setores');
    }

    const formEdit = {
        // formType: 'prepend',
        action: '/empresa/setores/' + id,
        method: 'PUT',
        data: data,
        onSubmitSuccess,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: '6', required: 'Nome não informado!', autoFocus: true },
            { name: 'code', type: 'text', label: 'Código', col: '4' },
            { name: 'active', type: 'select', label: 'Situação', col: '2', options: [{ label: "Ativo", value: true }, { label: "Inativo", value: false }] },
            { name: 'email', type: 'email', label: 'E-mail', col: '12' },
            { name: 'phone', type: 'text', label: 'Telefone', col: '6', mask: '(99) 99999-9999' },
            { name: 'whatsapp', type: 'text', label: 'Whatsapp', col: '6', mask: '(99) 99999-9999' },
        ],
        submits: [
            { label: 'Salvar', class: "btn btn-primary" }
        ],
    };

    return (
        <>
            <Button className={props?.button ? props?.button : 'btn btn-falcon-primary'} variant="" onClick={() => setModalShow(true)}>
                <span className="fa-solid fas fa-chevron-right"></span>
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Editando Setor {data?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <RequestError error={error} />}
                    {isLoading && <RequestLoading />}
                    {!isLoading && !error && < FormPadrao {...formEdit} />}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalEditSetor;