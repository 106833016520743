import React from "react";
import { Outlet } from 'react-router-dom';


const LayoutContasReceber = props => {
    return (
        <>
            <div>Contas a Receber</div>
            <div className="layout-container-contas-receber">
                <Outlet />
            </div>
        </>
    );
}

export default LayoutContasReceber;