import React, { useState } from "react";
import FormPadrao from "../../utils/form";
import { Button, Modal } from "react-bootstrap";
import RequestError from "../../utils/request/RequestError";
import RequestLoading from "../../utils/request/RequestLoading";
import useLoadData from "../../../data/swr/useLoadData";
import { mutate } from "swr";


const ModalUserEdit = props => {

    const uid = props?.uid;
    const [modalShow, setModalShow] = useState(false);
    const { data, error, isLoading } = useLoadData(modalShow ? `/adm/users/${uid}` : null);

    function onSubmitSuccess() {
        setModalShow(false);
        mutate('/adm/users');
    }

    const formEdit = {
        // formType: 'prepend',
        action: '/adm/users/' + uid,
        method: 'PUT',
        data: data,
        onSubmitSuccess,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: 6, required: 'Nome não informado!', autoFocus: true },
            { name: 'surname', type: 'text', label: 'Apelido', col: 4 },
            { name: 'active', type: 'select', label: 'Situação', col: 2, options: [{ label: "Ativo", value: true }, { label: "Inativo", value: false }] },
            { name: 'phone', type: 'tel', label: 'Telefone', col: 4, mask: "(99) 99999-9999", required: "Telefone não informado!" },
            { name: 'document_number', type: 'text', label: 'Documento', col: 4, mask: "999.999.999-99" },
            { name: 'birth', type: 'date', label: 'Nascimento', col: 4 },
            { name: 'email', type: 'email', label: 'E-mail', col: 12, required: 'E-mail não informado!' },
            // { name: 'active', type: 'checkbox', label: '', col: 12, options: [{ label: 'Situação', value: '1' }] },

            { type: 'string', html: <><h5 className="mt-3 mb-2">Endereço</h5></>, col: 12 },

            { name: 'postal_code', type: 'text', label: 'CEP', col: 3, mask: "99999-999" },
            { name: 'city', type: 'text', label: 'Cidade', col: 9 },
            { name: 'district', type: 'text', label: 'Bairro', col: 7 },
            { name: 'uf', type: 'text', label: 'Estado', col: 3 },
            { name: 'number', type: 'text', label: 'Número', col: 2 },
            { name: 'country', type: 'text', label: 'País', col: 4 },
            { name: 'address', type: 'text', label: 'Endereço', col: 8 },

            { type: 'string', html: <><h5 className="mt-3 mb-2">Acesso</h5></>, col: 12 },
            { name: 'password', type: 'password', label: 'Senha', col: 6 },
            { name: 'password2', type: 'password', label: 'Confirmação de Senha', col: 6 },
        ],
        submits: [
            { label: 'Atualizar', class: "btn btn-primary" }
        ],
    };


    if (error) return (<RequestError error={error} />);
    if (isLoading) return (<RequestLoading />);
    return (
        <>
            <Button className="dropdown-item" variant="" onClick={() => setModalShow(true)}>
                Editar
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <div className="">
                        <h4 className="mb-1" id="staticBackdropLabel">Editando Usuário</h4>
                        <p className="fs--2 mb-0 d-none">Usuário administrador</p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalUserEdit;