import React from 'react';
import { Card } from 'react-bootstrap';
import useLoadData from '../../../data/swr/useLoadData';
import RequestError from '../../utils/request/RequestError';
import RequestLoading from '../../utils/request/RequestLoading';
import ModalModuleAdd from './ModalModuleAdd';
import { Link } from 'react-router-dom';

const Modules = (props) => {
  const { data, error, isLoading } = useLoadData('/adm/modules');
  if (error) return <RequestError error={error} />;
  if (isLoading) return <RequestLoading />;

  return (
    <Card>
      <Card.Header>
        <div className="row flex-between-center">
          <div className="col-4 col-sm-auto d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Módulos</h5>
          </div>
          <div className="col-8 col-sm-auto text-end-- ps-2">
            <ModalModuleAdd />
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <table className="table table-sm table-striped fs--1 mb-0 overflow-hidden">
          <thead className="bg-200 text-900">
            <tr>
              <th className="col">#</th>
              <th className="col">Nome</th>
              <th className="col">Icone</th>
              <th className="col">Descrição</th>
              <th className="col">Ordem</th>
              <th className="col">Cadastro</th>
              <th className="col"></th>
            </tr>
          </thead>
          <tbody>
            {data?.modules?.map((e) => {
              return (
                <tr key={e?.id}>
                  <td className="text-nowrap py-2">
                    <b>#{e?.id}</b>
                  </td>
                  <td className="text-nowrap py-2">
                    <Link to={`/adm/modulos/${e?.id}`}>
                      <div className="d-flex align-items-center">
                        <h6 className="m-0">
                          <span className="text-900">{e?.name}</span>
                        </h6>
                      </div>
                    </Link>
                  </td>
                  <td className="text-nowrap py-2">
                    <i className={e?.icon}></i>
                  </td>
                  <td className="text-nowrap py-2">{e?.description}</td>
                  <td className="text-nowrap py-2">{e?.or}</td>
                  <td className="text-nowrap py-2">{e?.created_at}</td>
                  <td className="text-nowrap py-2">
                    <div className="dropdown font-sans-serif position-static">
                      <button
                        className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                        type="button"
                        data-bs-toggle="dropdown"
                        data-boundary="window"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="fas fa-ellipsis-h fs--1"></span>
                      </button>
                      <div className="dropdown-menu dropdown-menu-end border py-0">
                        <div className="py-2">
                          <Link to={`/adm/modulos/${e?.id}`} className="dropdown-item">
                            Editar
                          </Link>
                          {/* <ModalModuleEdit uid={e?.id} /> */}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
};

export default Modules;
