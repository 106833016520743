import React, { useState } from "react";
import FormPadrao from "../../../utils/form";
import { Button, Modal } from "react-bootstrap";
import { mutate } from "swr";

const ModalNewJobs = props => {

    const [modalShow, setModalShow] = useState(false);

    function onSubmitSuccess() {
        setModalShow(false);
        mutate('/empresa/cargos');
    }

    const formEdit = {
        // formType: 'prepend',
        action: '/empresa/cargos',
        method: 'POST',
        onSubmitSuccess,
        fields: [
            { name: 'name', type: 'text', label: 'Nome', col: '8', required: 'Nome não informado!' },
            { name: 'code', type: 'text', label: 'Código', col: '4' },
            { name: 'assignments', type: 'textarea', label: 'Atribuições', col: '12' },
        ],
        submits: [
            { label: 'Cadastrar', class: "btn btn-primary" }
        ],
    };

    return (
        <>
            <Button className={props?.button ? props?.button : 'btn btn-falcon-primary'} variant="" onClick={() => setModalShow(true)}>
                <span className="fas fa-plus"></span>
                <span className="d-none d-sm-inline-block ms-1">Novo Cargo</span>
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Novo Cargo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalNewJobs;