import { toast } from 'react-toastify';

const ShowError = (error) => {
  if (error?.response?.data?.error) error = error?.response?.data?.error;
  return toast.error(error);
};

export const ShowErrorSocket = (err) => {
  const error = err.error ? err.error : err;
  console.log(error);
  if (error?.message && typeof error.message === 'string') {
    toast.error(error.message);
  } else if (error?.message && error?.message?.error) {
    toast.error(error.message.name + ' - ' + error.message.error);
  } else if (typeof error === 'string') {
    toast.error(error.message);
  } else {
    console.log(error);
  }
};

export default ShowError;
