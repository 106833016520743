import React from "react";
import { Link } from "react-router-dom";
import { Outlet } from 'react-router-dom';


const LayoutFinanceiro = props => {
    return (
        <>
            <div className="layout-container">
                <nav className="nav d-none" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={'/'}>Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={'/'}>Financeiro</Link>
                        </li>
                    </ol>
                </nav>
                <div className="layout-container-financeiro">
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export default LayoutFinanceiro;